import React from 'react';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '../../../../../ui';

import { useRequest } from '../../../../../hooks/useRequest';

export function DeleteCategoryDialog({
  open,
  onOpenChange,
  onSuccess,
  categoryToDelete,
}) {
  const { request } = useRequest();

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Delete category</h1>
      </DialogHeader>
      <DialogBody>
        <p>
          Are you sure you want to delete the{' '}
          <strong>{categoryToDelete.name}</strong> category? Deleting{' '}
          <strong>{categoryToDelete.name}</strong> will delete all of it's
          posts.
        </p>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='secondary'
          label='Cancel'
          onClick={() => onOpenChange()}
        />
        <Button
          variant='danger'
          label='Yes, delete'
          onClick={() => {
            request({
              method: 'delete',
              url: `/api/v1/post-categories/${categoryToDelete.unique_id}`,
              onSuccess: () => onSuccess(),
              onError: () => {},
            });
          }}
        />
      </DialogFooter>
    </Dialog>
  );
}
