import { useRouter } from '../../../../hooks';

export function formatEvents(events) {
  const ROUTER = useRouter();

  if (!events || events.length === 0) {
    return [];
  }

  return events.map((event) => {
    const date = new Date(event.start_at);
    const formattedDate = date.toISOString().split('T')[0];

    return {
      title: event.title,
      date: formattedDate,
      url: `${ROUTER.PATH.EVENT_BASE}/${event.unique_id}`,
    };
  });
}
