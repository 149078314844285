import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { AccountContext } from '../../contexts/account';
import { useRequest } from '../../hooks';

import styles from './Onboarding.module.css';

import { SuperSweetLogo } from '../../ui/logos/SuperSweet';

export function Onboarding() {
  const [error, setError] = useState(null);
  const [name, setName] = useState('');

  const { register, handleSubmit } = useForm();

  const { request } = useRequest();

  const account = useContext(AccountContext);

  if (!account || account.default_organization) {
    return null;
  }

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: '/api/v1/organizations',
      data: { name: data.name },
      onSuccess: (res) => {
        // Force refresh
        window.location = `/${res.organization_slug}/projects`;
      },
      onError: (err) => {
        setError(err.error[0]);
      },
    });
  };

  return (
    <div
      className={clsx(
        styles.base,
        'flex w-full min-h-screen flex-col justify-center items-center '
      )}
    >
      <SuperSweetLogo className={clsx(styles.logo, 'mb-8')} />
      <h1
        className={clsx(
          styles.heading,
          'text-2xl font-semibold mb-4 text-center'
        )}
      >
        Let’s start by picking a name for your organization
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col w-1/3 justify-center items-center'
      >
        <input
          type='text'
          {...register('name', { required: true })}
          className={clsx(
            styles.field,
            'mb-8 w-full p-4 border-b-2 font-semibold'
          )}
          onChange={(e) => {
            setName(e.target.value);
            if (error) {
              setError(null);
            }
          }}
        />
        {error && <span className={clsx(styles.error, 'my-4')}>{error}</span>}
        <input
          className={clsx(
            styles.button,
            'mt-8 py-4 px-6 font-semibold rounded-2xl'
          )}
          type='submit'
          value={`Let's go!`}
          disabled={!!error || name === ''}
        />
      </form>
    </div>
  );
}
