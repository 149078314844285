import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { AccountContext } from '../../../contexts/account';

import {
  Button,
  Card,
  CardRow,
  Header,
  PageLayout,
  Label,
  Input,
} from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';

import styles from './AccountIndex.module.css';

export function AccountIndex() {
  const { request, data } = useRequest();
  const { request: avatarRequest, data: avatarData } = useRequest();

  const { request: updateRequest, data: updateData, isLoading } = useRequest();
  const ROUTER = useRouter();

  const account = useContext(AccountContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    request({
      method: 'put',
      url: ROUTER.API.ACCOUNT,
      data,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  const uploadFile = (data) => {
    avatarRequest({
      method: 'post',
      url: `/api/v1/uploads-avatars?user_unique_id=${account?.unique_id}`,
      data,
      onSuccess: () => {
        window.location.reload();
      },
    });
  };

  return (
    <PageLayout>
      <Header>
        <h1>Account</h1>
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className={clsx(styles.card)}>
          <CardRow className={clsx(styles['card-row'])}>
            <input
              id='avatar'
              type='file'
              name='name'
              style={{ display: 'none' }}
              onChange={(event) => {
                const file = event.currentTarget.files[0];
                const formData = new FormData();
                formData.append('file', file);
                uploadFile(formData);
              }}
            />
            <button
              className={clsx(styles['avatar-button'])}
              onClick={() => {
                document.getElementById('avatar')?.click();
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    avatarData?.avatar_url || account?.avatar_url
                  })`,
                }}
                className={clsx(styles.avatar)}
              />
              <div className={clsx(styles['upload-icon'])}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.6157 7.11581L18.5014 5.22177C19.0315 4.68941 19.1632 3.86631 18.7594 3.21582C18.2713 2.42948 17.6037 1.75331 16.8267 1.25131C16.204 0.848959 15.3862 0.937079 14.8626 1.46297L12.917 3.41714M16.6157 7.11581L5.52811 18.2521C5.25274 18.5287 5.11505 18.667 4.95435 18.7658C4.81188 18.8534 4.65654 18.9178 4.49406 18.9568C4.31079 19.0008 4.11608 19.0005 3.72665 18.9999L1 18.9955L1.06616 16.3151C1.07546 15.9381 1.08011 15.7497 1.12568 15.5726C1.16608 15.4156 1.23007 15.2658 1.31544 15.1282C1.41171 14.973 1.54444 14.8396 1.8099 14.573L12.917 3.41714M16.6157 7.11581L12.917 3.41714'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </button>
          </CardRow>
          <CardRow className={clsx(styles['card-row'])}>
            <Label label='email'></Label>
            <p>{account?.email}</p>
          </CardRow>
          <CardRow className={clsx(styles['card-row'])}>
            <Label label='Name' name='name'>
              <Input
                register={register}
                name='name'
                defaultValue={account?.name}
              />
            </Label>
          </CardRow>
          <CardRow className={clsx(styles['card-footer'])}>
            <Button
              label='Reset'
              variant='secondary'
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            />
            <Button
              label={isLoading ? 'Updating...' : 'Update'}
              disabled={isLoading}
            />
          </CardRow>
        </Card>
      </form>
    </PageLayout>
  );
}
