export function toLongDate({
  date,
  withTime = false,
}: {
  date: Date;
  withTime?: boolean;
}) {
  if (!date) {
    return null;
  }

  const initialDate = new Date(date.toString());

  const newDate = initialDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (!withTime) {
    return newDate;
  }

  const time = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return `${newDate} at ${time}`;
}
