import React, { useContext } from 'react';
import clsx from 'clsx';

import { Button } from '../../../../ui';
import { SuperSweetLogo } from '../../../../ui/logos/SuperSweet';

import { AccountContext } from '../../../../contexts/account';

import styles from './Error.module.css';

export function Error({ error }) {
  const account = useContext(AccountContext);

  return (
    <div className={clsx(styles.base)}>
      <SuperSweetLogo className={styles.logo} />
      <h1>Oops! Something went wrong</h1>
      <p>{error}</p>
      <Button
        label='Back Home'
        variant='secondary'
        onClick={() =>
          (window.location = `/${account?.default_organization?.slug}/projects`)
        }
      />
    </div>
  );
}
