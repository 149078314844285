import React, { useEffect } from 'react';

import { AccountContext } from './AccountContext';
import { useRequest } from '../../hooks';
import { useRouter } from '../../hooks';

export function AccountProvider({ children }) {
  const { request, data } = useRequest();
  const ROUTER = useRouter();

  const fetchAccount = async () => {
    request({
      url: ROUTER.API.ACCOUNT,
    });
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <AccountContext.Provider value={data}>{children}</AccountContext.Provider>
  );
}
