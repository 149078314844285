import React from 'react';
import clsx from 'clsx';

import styles from './CardRow.module.css';

export function CardRow({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={clsx(styles.base, className)}>{children}</div>;
}
