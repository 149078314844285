import { useState } from 'react';
import axios from 'axios';

export function useRequest() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = ({
    method = 'get',
    url,
    data: payload,
    params,
    onSuccess,
    onError,
  }: {
    method?: 'get' | 'post' | 'put' | 'delete';
    url: string;
    data?: object;
    params?: any;
    onSuccess?: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    setIsLoading(true);

    axios({
      method,
      url,
      data: payload,
      params,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
    })
      .then((res) => {
        setError(null);
        setIsLoading(false);
        setData(res.data?.payload);
        if (onSuccess) {
          onSuccess(res.data?.payload);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response?.data);

        if (onError) {
          onError(err.response?.data);
        }
      });
  };
  return { data, isLoading, error, request };
}
