import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import {
  Button,
  Card,
  CardRow,
  SectionHeader,
  DatePicker,
} from '../../../../../ui';
import { Editor } from '../../../../../features/Editor';

import { useRequest, useRouter } from '../../../../../hooks';

import styles from '../../TasksShow.module.css';

export function EditTask({ task, handleIsEditing, onSuccess }) {
  const [updatedDescription, setUpdatedDescription] = useState(
    task.description
  );
  const [updatedDueAt, setUpdatedDueAt] = useState(task.dueAt);
  const [_attachments, _setAttachments] = useState([]);

  const { request } = useRequest();
  const ROUTER = useRouter();

  const onSubmitUpdate = () => {
    request({
      url: ROUTER.API.TASKS_UPDATE,
      method: 'put',
      data: {
        name: task.name,
        due_at: updatedDueAt,
        description: updatedDescription,
        attachments: _attachments,
      },
      onSuccess: () => {
        handleIsEditing(false);
        onSuccess();
      },
    });
  };

  return (
    <Card className={clsx(styles.card)}>
      <CardRow className={clsx(styles['card-row'])}>
        <SectionHeader text='Due at' />

        <DatePicker
          initialDate={task.due_at}
          name='dueAt'
          onChange={(date) => {
            setUpdatedDueAt(date);
          }}
        />
      </CardRow>
      <CardRow className={clsx(styles['card-row'])}>
        <SectionHeader text='Description' />

        <Editor
          defaultValue={updatedDescription}
          setContent={setUpdatedDescription}
          getAttachments={_setAttachments}
          initialAttachments={task.attachments}
        />
      </CardRow>

      <CardRow className={clsx(styles['card-row'], styles.footer)}>
        <div className={clsx(styles.actions)}>
          <Button
            variant='secondary'
            label='Discard Changes'
            onClick={() => handleIsEditing(false)}
          />

          <Button label='Save Changes' onClick={onSubmitUpdate} />
        </div>
      </CardRow>
    </Card>
  );
}
