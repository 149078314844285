import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';

import { AccountContext } from '../../contexts/account';
import { InvitePeopleDialog } from './components/InvitePeopleDialog';
import {
  Button,
  Card,
  CardRow,
  Header,
  EmptyState,
  PageLayout,
  TrashIcon,
  Select,
  SelectItem,
} from '../../ui';

import { useRequest, useRouter } from '../../hooks';

import styles from './People.module.css';

export function People() {
  const [showInvitePeopleDialog, setShowInvitePeopleDialog] = useState(false);

  const { request, data } = useRequest();
  const ROUTER = useRouter();

  const { permissions } = useContext(AccountContext);

  const fetchOrganizationUsers = async () => {
    request({
      url: ROUTER.API.ORGANIZATION_USERS_BASE,
    });
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  return (
    <PageLayout>
      <Header>
        <h1>People</h1>
        {permissions?.can_manage_people && (
          <Button
            onClick={() => setShowInvitePeopleDialog(true)}
            label='Invite People'
          />
        )}
      </Header>
      {!permissions?.can_manage_people && (
        <EmptyState className={styles['empty-state']}>
          <svg
            width='24'
            height='20'
            viewBox='0 0 24 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 11.0001V7.0001M12 14.3751V14.3762M10.6101 1.28373C11.4989 0.905421 12.5011 0.905421 13.3899 1.28373C16.0413 2.41217 22.3045 12.4218 22.1211 15.0972C22.0491 16.1459 21.5294 17.1112 20.6973 17.7421C18.4845 19.4193 5.51548 19.4193 3.30278 17.7421C2.47058 17.1112 1.9509 16.1459 1.87895 15.0972C1.69539 12.4218 7.95863 2.41217 10.6101 1.28373Z'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p>Please contact an organization admin to add or remove people</p>
        </EmptyState>
      )}
      <Card>
        {data?.map((user) => (
          <CardRow key={user.email} className={clsx(styles.row)}>
            <div className={styles['avatar-wrapper']}>
              <img
                src={user.avatar_url}
                className={styles.avatar}
                alt={user.email}
              />
              <span>
                {user.email} ({user.role})
              </span>
            </div>
            {permissions?.can_manage_people && (
              <div className={clsx(styles['actions-wrapper'])}>
                <Select
                  value={user.role}
                  onChange={(value) => {
                    request({
                      method: 'put',
                      url: ROUTER.API.ORGANIZATION_USERS_BASE,
                      data: { user_id: user.id, role: value },
                      onSuccess: fetchOrganizationUsers,
                    });
                  }}
                >
                  <SelectItem value='admin'>Admin</SelectItem>
                  <SelectItem value='member'>Member</SelectItem>
                </Select>
                <Button
                  variant='secondary'
                  label='Remove'
                  icon={<TrashIcon />}
                  onClick={() => {
                    request({
                      method: 'delete',
                      url: ROUTER.API.ORGANIZATION_USERS_BASE,
                      data: { email: user.email },
                      onSuccess: fetchOrganizationUsers,
                    });
                  }}
                />
              </div>
            )}
          </CardRow>
        ))}
      </Card>
      <InvitePeopleDialog
        open={showInvitePeopleDialog}
        onClose={() => setShowInvitePeopleDialog(false)}
        handleRefetch={() => window.location.reload()}
      />
    </PageLayout>
  );
}
