import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
} from '../../../../../ui';
import { EmojiPicker } from '../../../../../features';
import { useRequest, useRouter } from '../../../../../hooks';

import styles from './NewProject.module.css';

export function NewProjectModal({ open, onOpenChange, onSuccess }) {
  const [emoji, setEmoji] = useState('');
  const { data, request } = useRequest();

  const { register, handleSubmit, reset, watch } = useForm();

  const ROUTER = useRouter();

  const name = watch('name');

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: '/api/v1/projects',
      data: {
        project_name: data.name,
        emoji,
        organization_slug: ROUTER.PARAMS.ORGANIZATION_SLUG,
      },
      onSuccess: () => {
        onSuccess();
        reset();
        setEmoji('');
        onOpenChange();
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Create a new project</h1>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className={clsx(styles.base)}>
          <EmojiPicker getEmoji={setEmoji} />
          <Label label='Project name' name='name'>
            <Input register={register} name='name' required />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            type='submit'
            label='Create Project'
            disabled={!(name && emoji)}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
