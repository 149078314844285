import React from 'react';
import clsx from 'clsx';

import { Header, PageLayout } from '../../ui';
import { PageCard } from './components/PageCard';

import { useRouter } from '../../hooks';

import styles from './Settings.module.css';

export function Settings() {
  const ROUTER = useRouter();

  return (
    <PageLayout>
      <Header>
        <h1>Settings</h1>
      </Header>
      <div className={clsx(styles.grid)}>
        <ROUTER.LINK to={ROUTER.PATH.ACCOUNT_INDEX}>
          <PageCard
            pageName='Account'
            icon={
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.9946 18.1467C17.893 16.3926 16.3672 15 14.5 15H7.5C5.63276 15 4.10703 16.3926 4.00539 18.1467M17.9946 18.1467C19.8491 16.3314 21 13.8001 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 13.8001 2.15087 16.3314 4.00539 18.1467M17.9946 18.1467C16.1913 19.9119 13.7227 21 11 21C8.27726 21 5.80867 19.9119 4.00539 18.1467M14 9C14 10.6569 12.6569 12 11 12C9.3431 12 8 10.6569 8 9C8 7.34315 9.3431 6 11 6C12.6569 6 14 7.34315 14 9Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
        <ROUTER.LINK to={ROUTER.PATH.BILLING}>
          <PageCard
            pageName='Billing'
            icon={
              <svg
                width='22'
                height='18'
                viewBox='0 0 22 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 6H21M5 10H8M14.6 17H7.4C5.15979 17 4.03969 17 3.18404 16.564C2.43139 16.1805 1.81947 15.5686 1.43597 14.816C1 13.9603 1 12.8402 1 10.6V7.4C1 5.15979 1 4.03968 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H14.6C16.8402 1 17.9603 1 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C21 4.03968 21 5.15979 21 7.4V10.6C21 12.8402 21 13.9603 20.564 14.816C20.1805 15.5686 19.5686 16.1805 18.816 16.564C17.9603 17 16.8402 17 14.6 17Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
      </div>
    </PageLayout>
  );
}
