import React from 'react';
import clsx from 'clsx';

import { Button, CardRow } from '../../';

import styles from './Attachment.module.css';

export function Attachment({
  preview,
  icon,
  filename,
  size,
  url,
  setAttachments,
}) {
  return (
    <CardRow className={clsx(styles.base)}>
      <div className={clsx(styles.file)}>
        <div
          className={clsx(styles.preview)}
          style={{ backgroundImage: `url(${url})` }}
        />
        <div className={clsx(styles.details)}>
          <span className={clsx(styles.filename)}>{filename}</span>
          <span className={clsx(styles.size)}>{size}</span>
        </div>
      </div>
      <div className={clsx(styles.actions)}>
        {setAttachments ? (
          <Button
            variant='secondary'
            icon={
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.99976 13.9998L10.9998 10.9998M10.9998 10.9998L13.9998 7.99976M10.9998 10.9998L7.99976 7.99976M10.9998 10.9998L13.9998 13.9998M10.9996 20.1496C5.9462 20.1496 1.84961 16.053 1.84961 10.9996C1.84961 5.9462 5.9462 1.84961 10.9996 1.84961C16.053 1.84961 20.1496 5.9462 20.1496 10.9996C20.1496 16.053 16.053 20.1496 10.9996 20.1496Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            label='Remove'
            onClick={setAttachments}
          />
        ) : (
          <Button
            variant='secondary'
            icon={icon}
            label='Download'
            onClick={() => {
              window.open(url, '_blank').focus();
            }}
          />
        )}
      </div>
    </CardRow>
  );
}
