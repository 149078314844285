import React from 'react';

export function GoogleDriveLogo() {
  return (
    <svg
      width='25'
      height='22'
      viewBox='0 0 25 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_451_3224)'>
        <path
          d='M1.86154 18.8556L2.94744 20.7313C3.17308 21.1261 3.49744 21.4364 3.87821 21.662L7.75641 14.9492H0C0 15.3864 0.112821 15.8236 0.338462 16.2184L1.86154 18.8556Z'
          fill='#0066DA'
        />
        <path
          d='M12.3115 7.05071L8.43333 0.337891C8.05256 0.563532 7.72821 0.873788 7.50256 1.26866L0.338462 13.6789C0.11697 14.0653 0.000294902 14.5028 0 14.9481H7.75641L12.3115 7.05071Z'
          fill='#00AC47'
        />
        <path
          d='M20.745 21.662C21.1258 21.4364 21.4501 21.1261 21.6757 20.7313L22.127 19.9556L24.2847 16.2184C24.5104 15.8236 24.6232 15.3864 24.6232 14.9492H16.8662L18.5168 18.1928L20.745 21.662Z'
          fill='#EA4335'
        />
        <path
          d='M12.3118 7.05128L16.19 0.338462C15.8092 0.112821 15.3721 0 14.9208 0H9.70283C9.25154 0 8.81436 0.126923 8.43359 0.338462L12.3118 7.05128Z'
          fill='#00832D'
        />
        <path
          d='M16.8664 14.9492H7.75613L3.87793 21.662C4.2587 21.8877 4.69588 22.0005 5.14716 22.0005H19.4754C19.9266 22.0005 20.3638 21.8736 20.7446 21.662L16.8664 14.9492Z'
          fill='#2684FC'
        />
        <path
          d='M20.7025 7.47379L17.1205 1.26866C16.8949 0.873788 16.5705 0.563532 16.1897 0.337891L12.3115 7.05071L16.8667 14.9481H24.609C24.609 14.511 24.4961 14.0738 24.2705 13.6789L20.7025 7.47379Z'
          fill='#FFBA00'
        />
      </g>
      <defs>
        <clipPath id='clip0_451_3224'>
          <rect width='24.6231' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
