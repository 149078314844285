import React from 'react';
import clsx from 'clsx';

import styles from './SectionHeader.module.css';

export function SectionHeader({
  className,
  text,
  icon,
}: {
  className?: string;
  text: string;
  icon?: React.ReactNode;
}) {
  return (
    <h3 className={clsx(styles.base, className)}>
      {icon} {text}
    </h3>
  );
}
