import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Label,
  Button,
  DatePicker,
} from '../../../../../ui';
import { isValidDate } from '../../../../../utils/dates/validDate';
import { useRequest, useRouter } from '../../../../../hooks';

type Inputs = {
  title: string;
  description: string;
  date: string;
};

export function NewEventModal({
  isOpen,
  setIsOpen,
  refetchEvents,
}: {
  isOpen: boolean;
  setIsOpen: () => {};
  refetchEvents: () => void;
}): React.ReactNode {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const ROUTER = useRouter();

  const [starts, setStarts] = useState(null);
  const [ends, setEnds] = useState(null);

  const { request } = useRequest();

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: '/api/v1/events',
      data: {
        ...data,
        notes: `<p>${data.notes}</p>`,
        start_at: starts,
        end_at: ends,
        project_unique_id: ROUTER.PARAMS.PROJECT_UNIQUE_ID,
      },
      onSuccess: () => {
        refetchEvents();
        reset();
        setStarts(null);
        setEnds(null);
        setIsOpen();
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <h1>Add New Event</h1>
        </DialogHeader>
        <DialogBody>
          <Label name='title' label='Title'>
            <Input register={register} name='title' />
            {errors.title && <span>This field is required</span>}
          </Label>

          <div className='row'>
            <div className='col-md-6'>
              <Label name='starts' label='Starts'>
                <DatePicker name='starts' onChange={setStarts} />
              </Label>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <Label name='ends' label='Ends'>
                <DatePicker startDate={starts} name='ends' onChange={setEnds} />
              </Label>
            </div>
          </div>

          <Label name='notes' label='Notes'>
            <Input register={register} name='notes' />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            label='Cancel'
            variant='secondary'
            onClick={() => setIsOpen(false)}
          />
          <Button
            label='Add'
            disabled={
              !starts ||
              !isValidDate(starts) ||
              (ends && !isValidDate(ends)) ||
              (ends && starts > ends)
            }
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
