import React from 'react';

export function PlusCircleIcon({ className }: { className?: string }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12.0001 15V12M12.0001 12V8.99998M12.0001 12H9.0001M12.0001 12H15.0001M21.1501 12C21.1501 14.4267 20.1861 16.754 18.4701 18.47C16.7542 20.186 14.4268 21.15 12.0001 21.15C9.57337 21.15 7.24603 20.186 5.53007 18.47C3.81411 16.754 2.8501 14.4267 2.8501 12C2.8501 9.57324 3.81411 7.24591 5.53007 5.52995C7.24603 3.81399 9.57337 2.84998 12.0001 2.84998C14.4268 2.84998 16.7542 3.81399 18.4701 5.52995C20.1861 7.24591 21.1501 9.57324 21.1501 12Z'
        stroke='#000000'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
