import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

export const RadioGroup = ({ children, onChange }) => (
  <RadioGroupPrimitive.Root
    className='RadioGroupRoot'
    defaultValue='default'
    aria-label=''
    onChange={onChange}
  >
    {children}
  </RadioGroupPrimitive.Root>
);
