import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogBody,
  DialogFooter,
  Input,
  Label,
  Button,
  DialogHeader,
} from '../../../../../ui';
import { EmojiPicker } from '../../../../../features';
import { useRequest, useRouter } from '../../../../../hooks';

export function EditCategoryDialog({
  open,
  onOpenChange,
  initialValues,
  onSuccess,
}) {
  const [emoji, setEmoji] = useState('');
  const ROUTER = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const name = watch('name');

  const { request, isLoading } = useRequest();

  const onSubmit = (data) => {
    request({
      method: 'put',
      url: `${ROUTER.API.POST_CATEGORIES_UPDATE}/${initialValues.unique_id}`,
      data: {
        ...data,
        emoji,
      },
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={() => onOpenChange(false)}>
      <DialogHeader>
        <h1>Edit category</h1>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          <Label label='Choose an emoji'></Label>
          <EmojiPicker getEmoji={setEmoji} initialValue={initialValues.emoji} />
          <Label label='Name'>
            <Input
              register={register}
              name='name'
              defaultValue={initialValues.name}
            />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            label='Cancel'
            variant='secondary'
            onClick={() => onOpenChange(false)}
          />
          <Button
            label={isLoading ? 'Updating...' : 'Update'}
            type='submit'
            disabled={isLoading || !emoji || !name}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
