import React, { useEffect } from 'react';

import { useRequest } from '../../hooks';

import { ReadComment } from './components/ReadComment';
import { NewComment } from './components/NewComment';

import { Card, Header } from '../../ui';

export function Comments({ fetchEndpoint, createEndpoint }): React.ReactNode {
  const { data: comments, request } = useRequest();

  const fetchComments = () => {
    request({
      url: fetchEndpoint,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <Header>
        <h2>
          {' '}
          <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.5 10H16.5M8.5 14H12.5M21.5 12C21.5 16.9706 17.4706 21 12.5 21C12.0683 21 11.6437 20.9696 11.2283 20.9108C10.0246 20.7406 9.42273 20.6555 9.26429 20.6433C9.0069 20.6234 9.09919 20.6266 8.84105 20.6286C8.68217 20.6298 8.50106 20.6428 8.1393 20.6686L5.98597 20.8224C5.12856 20.8837 4.69982 20.9143 4.37922 20.7623C4.09778 20.6289 3.87113 20.4022 3.7377 20.1208C3.5857 19.8002 3.61632 19.3715 3.67757 18.514L3.83138 16.3607C3.85723 15.9988 3.87015 15.8179 3.87139 15.6589C3.87339 15.4008 3.87659 15.4931 3.85674 15.2357C3.84452 15.0773 3.7594 14.4754 3.58915 13.2717C3.53039 12.8563 3.5 12.4317 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z'
              stroke='#111111'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Comments
        </h2>
      </Header>
      <Card>
        {comments?.map((comment) => {
          return <ReadComment key={comment.unique_id} comment={comment} />;
        })}
        <NewComment
          createEndpoint={createEndpoint}
          handleRefetch={fetchComments}
        />
      </Card>
    </>
  );
}
