import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import clsx from 'clsx';

// TipTap Editor extensions
import { extensions } from './extensions';

import { MenuBar } from './components/MenuBar';
import { AttachmentsList } from './components/AttachmentsList/AttachmentsList';

import styles from './Editor.module.css';

export function Editor({
  setContent,
  defaultValue,
  getAttachments = () => {},
  initialAttachments = [],
  className,
  hideFeatures,
}: {
  setContent: Dispatch<SetStateAction<string>>;
  defaultValue?: string;
  className?: string;
  getAttachments: Dispatch<SetStateAction<never[]>>;
  initialAttachments?: [];
  hideFeatures?: [];
}) {
  // Source of truth for all attachments
  const [attachments, setAttachments] = useState(initialAttachments);
  const [isUploading, setIsUploading] = useState(false);

  // // Send attachments to parent in order to send along with request
  useEffect(() => {
    getAttachments(attachments);
  }, [attachments]);

  const editor = useEditor(
    {
      extensions,
      content: defaultValue,
      onUpdate({ editor }) {
        // The content has changed
        setContent(editor.getHTML());
      },
    },
    []
  );

  return (
    <div className={clsx(styles.editor)}>
      <EditorContent editor={editor} className={clsx(className)} />
      <MenuBar
        editor={editor}
        attachments={attachments}
        setAttachments={setAttachments}
        setIsUploading={setIsUploading}
        hideFeatures={hideFeatures}
      />
      <AttachmentsList
        attachments={attachments}
        getAttachments={setAttachments}
        isUploading={isUploading}
      />
    </div>
  );
}
