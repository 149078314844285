import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useRouter } from '../../../hooks';
import { AccountContext } from '../../../contexts/account';

import { SuperSweetLogo } from '../../../ui/logos/SuperSweet';

import styles from './Nav.module.css';

export function Nav() {
  const ROUTER = useRouter();
  const account = useContext(AccountContext);

  return (
    <nav className={clsx(styles.nav, 'flex items-center justify-between')}>
      <div className='flex-col items-center justify-center'>
        <a
          href='/'
          className={clsx(
            styles['logo-link'],
            'flex items-center justify-center'
          )}
        >
          <SuperSweetLogo className={clsx(styles.logo)} />
        </a>

        <Link
          to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/settings/account`}
          className={clsx(styles.link, 'flex items-center justify-center')}
        >
          <div
            style={{
              backgroundImage: `url(${account?.avatar_url})`,
            }}
            className={clsx(styles.avatar)}
          />
        </Link>

        <ul>
          <li>
            <Link to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/projects`}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2 9.4C2 7.15979 2 6.03968 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03968 3 6.15979 3 8.4 3H8.71556C9.18517 3 9.41997 3 9.6331 3.06473C9.82179 3.12203 9.99732 3.21597 10.1497 3.34118C10.3217 3.48262 10.452 3.67798 10.7125 4.06872L11.2875 4.93128C11.548 5.32202 11.6783 5.51739 11.8503 5.65882C12.0027 5.78403 12.1782 5.87797 12.3669 5.93527C12.58 6 12.8148 6 13.2844 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03969 22 10.1598 22 12.4V14.6C22 16.8402 22 17.9603 21.564 18.816C21.1805 19.5686 20.5686 20.1805 19.816 20.564C18.9603 21 17.8402 21 15.6 21H8.4C6.15979 21 5.03968 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Projects
            </Link>
          </li>

          <li>
            <Link to={ROUTER.PATH.PEOPLE}>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.32836 6.19123C4.89484 6.19123 3.73274 5.02914 3.73274 3.59562C3.73274 2.1621 4.89484 1 6.32836 1C7.76188 1 8.92398 2.1621 8.92398 3.59562C8.92398 5.02914 7.76188 6.19123 6.32836 6.19123ZM6.32836 6.19123C8.25966 6.19123 9.967 7.15 11.0005 8.6175C12.0339 7.15 13.7413 6.19123 15.6726 6.19123M6.32836 6.19123C4.39706 6.19123 2.68969 7.15 1.65625 8.6175M15.6726 6.19123C14.2391 6.19123 13.077 5.02914 13.077 3.59562C13.077 2.1621 14.2391 1 15.6726 1C17.1061 1 18.2682 2.1621 18.2682 3.59562C18.2682 5.02914 17.1061 6.19123 15.6726 6.19123ZM15.6726 6.19123C17.6039 6.19123 19.3113 7.15 20.3447 8.6175M6.32836 16.5737C4.89484 16.5737 3.73274 15.4116 3.73274 13.9781C3.73274 12.5446 4.89484 11.3825 6.32836 11.3825C7.76188 11.3825 8.92398 12.5446 8.92398 13.9781C8.92398 15.4116 7.76188 16.5737 6.32836 16.5737ZM6.32836 16.5737C8.25966 16.5737 9.967 17.5325 11.0005 19C12.0339 17.5325 13.7413 16.5737 15.6726 16.5737M6.32836 16.5737C4.39706 16.5737 2.68969 17.5325 1.65625 19M15.6726 16.5737C14.2391 16.5737 13.077 15.4116 13.077 13.9781C13.077 12.5446 14.2391 11.3825 15.6726 11.3825C17.1061 11.3825 18.2682 12.5446 18.2682 13.9781C18.2682 15.4116 17.1061 16.5737 15.6726 16.5737ZM15.6726 16.5737C17.6039 16.5737 19.3113 17.5325 20.3447 19'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              People
            </Link>
          </li>

          <li>
            <Link to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/settings`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M10.398 4.28c.387-1.706 2.817-1.706 3.204 0l.051.225a1.642 1.642 0 0 0 2.478 1.027l.196-.124c1.48-.933 3.198.786 2.265 2.265l-.123.196a1.642 1.642 0 0 0 1.026 2.478l.226.051c1.705.387 1.705 2.817 0 3.204l-.226.051a1.642 1.642 0 0 0-1.027 2.478l.124.196c.933 1.48-.786 3.198-2.265 2.265l-.196-.123a1.642 1.642 0 0 0-2.478 1.026l-.051.226c-.387 1.705-2.817 1.705-3.204 0l-.051-.226a1.642 1.642 0 0 0-2.478-1.027l-.196.124c-1.48.933-3.198-.786-2.265-2.265l.124-.196a1.642 1.642 0 0 0-1.027-2.478l-.226-.051c-1.705-.387-1.705-2.817 0-3.204l.226-.051a1.643 1.643 0 0 0 1.027-2.478l-.124-.196c-.933-1.48.786-3.198 2.265-2.265l.196.124a1.643 1.643 0 0 0 2.478-1.027l.051-.226Z'
                />
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M10.99 12c0-.552.458-1 1.01-1s1.01.448 1.01 1-.458 1-1.01 1-1.01-.448-1.01-1Z'
                />
              </svg>
              Settings
            </Link>
          </li>
        </ul>
      </div>
      <ul>
        <li>
          <a href={`/logout`}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.1885 9C19.1755 9.74024 20.0668 10.599 20.8426 11.5564C20.9475 11.6859 21 11.843 21 12M21 12C21 12.157 20.9475 12.3141 20.8426 12.4436C20.0668 13.401 19.1755 14.2598 18.1885 15M21 12H8M13 4.52779C11.9385 3.57771 10.5367 3 9 3C5.68629 3 3 5.68629 3 9V15C3 18.3137 5.68629 21 9 21C10.5367 21 11.9385 20.4223 13 19.4722'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Log out
          </a>
        </li>
        <li>
          <Link to={'mailto:support@supersweetness.com'}>
            <svg
              width='23'
              height='18'
              viewBox='0 0 23 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.3032 4.76159L15.795 8.2668C14.2385 9.2573 13.4602 9.7526 12.6238 9.9455C11.8843 10.1161 11.1157 10.1161 10.3762 9.9455C9.5398 9.7526 8.76153 9.2573 7.20499 8.2668L1.69678 4.76159M21.3032 4.76159C21.5 5.72189 21.5 7.006 21.5 9C21.5 11.8003 21.5 13.2004 20.955 14.27C20.4757 15.2108 19.7108 15.9757 18.77 16.455C17.7004 17 16.3003 17 13.5 17H9.5C6.69974 17 5.29961 17 4.23005 16.455C3.28924 15.9757 2.52433 15.2108 2.04497 14.27C1.5 13.2004 1.5 11.8003 1.5 9C1.5 7.006 1.5 5.72189 1.69678 4.76159M21.3032 4.76159C21.2237 4.37332 21.1119 4.03798 20.955 3.73005C20.4757 2.78924 19.7108 2.02433 18.77 1.54497C17.7004 1 16.3003 1 13.5 1H9.5C6.69974 1 5.29961 1 4.23005 1.54497C3.28924 2.02433 2.52433 2.78924 2.04497 3.73005C1.88807 4.03798 1.77634 4.37332 1.69678 4.76159'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Need Help?
          </Link>
        </li>

        <li>
          <Link
            to={'https://supersweet.canny.io'}
            data-canny-link
            target='_blank'
            rel='noopener noreferrer'
          >
            <svg
              width='20'
              height='22'
              viewBox='0 0 20 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.9 10.4444V13.2222M4.9 10.4444V3.77778C4.9 2.8573 5.66112 2.11111 6.6 2.11111C7.53888 2.11111 8.3 2.8573 8.3 3.77778M4.9 10.4444C4.9 9.52397 4.13888 8.77778 3.2 8.77778C2.26112 8.77778 1.5 9.52397 1.5 10.4444V12.6667C1.5 17.269 5.30558 21 10 21C14.6944 21 18.5 17.269 18.5 12.6667V7.11111C18.5 6.19064 17.7389 5.44444 16.8 5.44444C15.8611 5.44444 15.1 6.19064 15.1 7.11111M8.3 3.77778V9.88889M8.3 3.77778V2.66667C8.3 1.74619 9.06112 1 10 1C10.9389 1 11.7 1.74619 11.7 2.66667V3.77778M11.7 3.77778V9.88889M11.7 3.77778C11.7 2.8573 12.4611 2.11111 13.4 2.11111C14.3389 2.11111 15.1 2.8573 15.1 3.77778V7.11111M15.1 7.11111V9.88889'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Requests
          </Link>
        </li>
      </ul>
    </nav>
  );
}
