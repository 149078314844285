export function toMonthAndDay(date) {
  if (!date) {
    return;
  }

  const newDate = new Date(date.toString());

  return newDate.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
  });
}
