import React, { useState, useEffect } from 'react';
import EmojiPickerReact from 'emoji-picker-react';
import clsx from 'clsx';

import styles from './EmojiPicker.module.css';

export function EmojiPicker({ getEmoji, initialValue = '' }) {
  const [emoji, setEmoji] = useState(initialValue);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    getEmoji(emoji);
  }, [emoji]);

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowEmojiPicker(true);
        }}
        className={clsx(styles.button)}
      >
        {emoji || (
          <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7 13C7 13 8.5 15 11 15C13.5 15 15 13 15 13M14 8H14.01M8 8H8.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM14.5 8C14.5 8.27614 14.2761 8.5 14 8.5C13.7239 8.5 13.5 8.27614 13.5 8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8ZM8.5 8C8.5 8.27614 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.27614 7.5 8C7.5 7.72386 7.72386 7.5 8 7.5C8.27614 7.5 8.5 7.72386 8.5 8Z'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
      </button>
      {showEmojiPicker && (
        <EmojiPickerReact
          onEmojiClick={(data, e) => {
            // Needed as EmojiPicker can appear within a form.
            // This allows the EmojiPicker to close when an emoji is selected.
            e.preventDefault();
            setShowEmojiPicker(false);
            setEmoji(data.emoji);
          }}
        />
      )}
    </>
  );
}
