import React, { useState } from 'react';
import clsx from 'clsx';
import DatePickerPrimitive from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './DatePicker.module.css';

export function DatePicker({
  name,
  onChange,
  className,
  initialDate,
}): React.ReactNode {
  const [date, setDate] = useState(null);

  return (
    <DatePickerPrimitive
      name={name}
      selected={date || initialDate}
      onChange={(date) => {
        setDate(date);
        if (onChange) {
          onChange(date);
        }
      }}
      value={date}
      className={clsx(styles, className)}
    />
  );
}
