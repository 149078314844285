import React from 'react';

import { EmptyState } from '../../../../ui';

import styles from '../../Billing.module.css';

export function NonAdmin() {
  return (
    <EmptyState className={styles.warning}>
      <svg
        width='24'
        height='20'
        viewBox='0 0 24 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 11.0001V7.0001M12 14.3751V14.3762M10.6101 1.28373C11.4989 0.905421 12.5011 0.905421 13.3899 1.28373C16.0413 2.41217 22.3045 12.4218 22.1211 15.0972C22.0491 16.1459 21.5294 17.1112 20.6973 17.7421C18.4845 19.4193 5.51548 19.4193 3.30278 17.7421C2.47058 17.1112 1.9509 16.1459 1.87895 15.0972C1.69539 12.4218 7.95863 2.41217 10.6101 1.28373Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      <p>
        Please contact an organization admin to view and manage billing details
      </p>
    </EmptyState>
  );
}
