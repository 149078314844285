import React, { useState } from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import clsx from 'clsx';

import styles from './Switch.module.css';

export function Switch({
  id,
  checked = false,
  onCheckedChange,
}: {
  id?: string;
  checked: boolean;
  onCheckedChange: () => void;
}) {
  return (
    <RadixSwitch.Root
      checked={checked}
      className={clsx(styles['switch-root'])}
      id={id}
      onCheckedChange={onCheckedChange}
    >
      <RadixSwitch.Thumb className={clsx(styles['switch-thumb'])} />
    </RadixSwitch.Root>
  );
}
