import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  Card,
  CardRow,
  Button,
  PageLayout,
  Header,
  EmptyState,
} from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';

import { toShortDate } from '../../../utils/dates/toShortDate';

import { DropboxLogo } from '../../../shared/logos/Dropbox';
import { FigmaLogo } from '../../../shared/logos/Figma';
import { NotionLogo } from '../../../shared/logos/Notion';
import { GoogleDocsLogo } from '../../../shared/logos/GoogleDocs';
import { GoogleDriveLogo } from '../../../shared/logos/GoogleDrive';
import { GoogleSheetsLogo } from '../../../shared/logos/GoogleSheets';
import { GoogleSlidesLogo } from '../../../shared/logos/GoogleSlides';
import { MicrosoftOneDriveLogo } from '../../../shared/logos/MicrosoftOneDrive';

import styles from './FilesIndex.module.css';

export function FilesIndex() {
  const { request, data, isLoading } = useRequest();
  const ROUTER = useRouter();

  const fetchFiles = () => {
    request({
      url: ROUTER.API.FILES_INDEX,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const hasNoFiles = !isLoading && !data?.length;

  const logoMap = {
    ['Dropbox']: <DropboxLogo />,
    ['Figma']: <FigmaLogo />,
    ['Google Docs']: <GoogleDocsLogo />,
    ['Google Drive']: <GoogleDriveLogo />,
    ['Google Sheets']: <GoogleSheetsLogo />,
    ['Google Slides']: <GoogleSlidesLogo />,
    ['Microsoft OneDrive']: <MicrosoftOneDriveLogo />,
    ['Notion']: <NotionLogo />,
    ['Other']: (
      <svg
        width='18'
        height='22'
        viewBox='0 0 18 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 10C17 8.34315 15.6569 7 14 7H13.4C13.0284 7 12.8426 7 12.6871 6.97538C11.8313 6.83983 11.1602 6.16865 11.0246 5.31287C11 5.1574 11 4.9716 11 4.6V4C11 2.34315 9.6569 1 8 1M17 9V17C17 19.2091 15.2091 21 13 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1H9C13.4183 1 17 4.58172 17 9Z'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  };

  return (
    <PageLayout>
      <Header>
        <h1>Files</h1>
        {data?.length ? (
          <Button
            label='Add File'
            variant='secondary'
            onClick={() => {
              ROUTER.NAVIGATE(ROUTER.PATH.FILES_NEW);
            }}
          />
        ) : null}
      </Header>
      {hasNoFiles ? (
        <EmptyState>
          <h2>You don't have any files yet</h2>
          <Button
            variant='secondary'
            label='Add a file'
            onClick={() => {
              ROUTER.NAVIGATE(ROUTER.PATH.FILES_NEW);
            }}
          />
        </EmptyState>
      ) : (
        <Card>
          {data?.map((file) => {
            return (
              <CardRow key={file.id} className={clsx(styles['card-row'])}>
                <div className={clsx(styles['logo-name-wrapper'])}>
                  <div className={clsx(styles.logo)}>
                    {logoMap[file.service_name]}
                  </div>

                  <ROUTER.LINK
                    to={`${ROUTER.PATH.FILES_SHOW}/${file.unique_id}`}
                  >
                    {file.custom_name}
                  </ROUTER.LINK>
                </div>
                <span>
                  Added {toShortDate({ date: file.created_at, withTime: true })}
                </span>
              </CardRow>
            );
          })}
        </Card>
      )}
    </PageLayout>
  );
}
