import React from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
} from '../../../../../ui';

import { useRequest, useRouter } from '../../../../../hooks';

import styles from './NewChecklist.module.css';

export function NewChecklistModal({ open, onOpenChange, onSuccess }) {
  const { request } = useRequest();
  const ROUTER = useRouter();

  const { register, handleSubmit, reset, watch } = useForm();

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: ROUTER.API.CHECKLISTS_CREATE,
      data: {
        name: data.name,
      },
      onSuccess: () => {
        onSuccess();
        reset();
        onOpenChange();
      },
      onError: () => {},
    });
  };

  const name = watch('name');

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Create a new checklist</h1>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className={clsx(styles.base)}>
          <Label label='Name' name='name'>
            <Input register={register} name='name' required />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            type='submit'
            label='Create'
            disabled={!(name && name.trim())}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
