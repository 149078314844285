import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { App } from '../src/app';

import { AccountIndex } from '../src/views/account/index';
import { Billing } from '../src/views/billing';
import { CalendarIndex } from '../src/views/calendar/index';
import { ChecklistsIndex } from '../src/views/checklists/index';
import { EventsEdit } from '../src/views/events/edit';
import { EventsShow } from '../src/views/events/show';
import { FilesIndex } from '../src/views/files/index';
import { FilesNew } from '../src/views/files/new';
import { FilesShow } from '../src/views/files/show';
import { Onboarding } from '../src/views/onboarding';
import { People } from '../src/views/people/index';
import { PostCategoriesIndex } from '../src/views/post-categories/index';
import { PostsCreate } from '../src/views/posts/create';
import { PostsIndex } from '../src/views/posts/index';
import { PostsShow } from '../src/views/posts/show';
import { ProjectsIndex } from '../src/views/projects/index';
import { ProjectsShow } from '../src/views/projects/show';
import { Settings } from '../src/views/settings/index';
// import { TasksNew } from '../src/views/tasks/new';
import { TasksShow } from '../src/views/tasks/show';

Sentry.init({
  dsn: 'https://c218be2f2fbe0ec2b7d1f832a3d2cbff@o4508123192819712.ingest.us.sentry.io/4508123320025088',
  environment: window.location.href.includes('localhost')
    ? 'development'
    : 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootNode = document.getElementById('root');

if (rootNode) {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '/onboarding',
          element: <Onboarding />,
        },
        {
          path: '/:organizationSlug/projects',
          element: <ProjectsIndex />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId',
          element: <ProjectsShow />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/checklists',
          element: <ChecklistsIndex />,
        },
        {
          path: '/:organizationSlug/settings',
          element: <Settings />,
        },
        {
          path: '/:organizationSlug/people',
          element: <People />,
        },
        {
          path: '/:organizationSlug/settings/account',
          element: <AccountIndex />,
        },
        {
          path: '/:organizationSlug/settings/billing',
          element: <Billing />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/checklists/:checklistUniqueId/task/:taskUniqueId',
          element: <TasksShow />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/calendar',
          element: <CalendarIndex />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/events/:eventUniqueId',
          element: <EventsShow />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/events/:eventUniqueId/edit',
          element: <EventsEdit />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/posts',
          element: <PostsIndex />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/posts/categories/:postCategoryUniqueId',
          element: <PostCategoriesIndex />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/posts/categories/:postCategoryUniqueId/new',
          element: <PostsCreate />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/posts/:postUniqueId',
          element: <PostsShow />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/files',
          element: <FilesIndex />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/files/new',
          element: <FilesNew />,
        },
        {
          path: '/:organizationSlug/projects/:projectUniqueId/files/:fileUniqueId',
          element: <FilesShow />,
        },
      ],
    },
  ]);

  createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
