import React from 'react';
import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom';

import { AccountProvider } from '../contexts/account';
import { Nav } from './components/Nav';
import { Router } from '../router';
import { Permissions } from '../router/permissions';

import styles from './App.module.css';

export function App() {
  const location = useLocation();
  const hideNavOnPaths = ['/', '/onboarding'];
  const showNav = !hideNavOnPaths.includes(location.pathname);

  return (
    <AccountProvider>
      <Router>
        <Permissions>
          <div className={clsx(styles.wrapper)}>
            {showNav && <Nav />}
            <Outlet />
          </div>
        </Permissions>
      </Router>
    </AccountProvider>
  );
}
