import React from 'react';
import clsx from 'clsx';

import { Card, CommunityIcon } from '../../../../../ui';

import styles from './ProjectCard.module.css';

export function ProjectCard({ project }) {
  const userCount = project.project_users.length;
  return (
    <Card className={clsx(styles['card-base'])}>
      <div className={clsx(styles.body)}>
        <div className={clsx(styles.emoji)}>{project.emoji}</div>
        <h2 className={clsx(styles['project-name'])}>{project.name}</h2>
      </div>
      <div className={clsx(styles.footer)}>
        <CommunityIcon className={clsx(styles['community-icon'])} />{' '}
        <span>
          {userCount} {userCount > 1 ? 'People' : 'Person'}
        </span>
      </div>
    </Card>
  );
}
