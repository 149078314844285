import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import {
  Button,
  Card,
  CardRow,
  DropdownMenu,
  PageLayout,
  Header,
  TrashIcon,
  PencilIcon,
  EmptyState,
} from '../../../ui';
import { NewCategoryDialog } from './components/NewCategoryDialog';
import { DeleteCategoryDialog } from './components/DeleteCategoryDialog/DeleteCategoryDialog';
import { EditCategoryDialog } from './components/EditCategoryDialog/EditCategoryDialog';

import styles from './PostsIndex.module.css';

import { useRequest, useRouter } from '../../../hooks';

export function PostsIndex() {
  const [showNewCategoryDialog, setShowNewCategoryDialog] = useState(false);
  const [showEditCategoryDialog, setShowEditCategoryDialog] = useState(false);

  const [categoryToDelete, setCategoryToDelete] = useState({});
  const [categoryToEdit, setCategoryToEdit] = useState({});

  const [showDeleteCategoryDialog, setShowDeleteCategoryDialog] =
    useState(false);

  const { request, data, isLoading } = useRequest();

  const ROUTER = useRouter();

  const fetchPostCategories = async () => {
    const response = await request({
      url: ROUTER.API.POST_CATEGORIES_INDEX,
    });
  };

  useEffect(() => {
    fetchPostCategories();
  }, []);

  const hasNoCategories = !isLoading && !data?.length;
  return (
    <PageLayout>
      <Header>
        <h1>Posts</h1>
        {data?.length ? (
          <Button
            label='New Category'
            variant='secondary'
            onClick={() => setShowNewCategoryDialog(true)}
          />
        ) : null}
      </Header>
      {hasNoCategories ? (
        <EmptyState>
          <h2>You don't have any categories yet</h2>

          <Button
            variant='secondary'
            label='Create a category'
            onClick={() => setShowNewCategoryDialog(true)}
          />
        </EmptyState>
      ) : null}
      {data?.length ? (
        <Card>
          {data.map((category) => (
            <CardRow key={category.name} className={clsx(styles['card-row'])}>
              <a
                href={`${ROUTER.PATH.POSTS_CATEGORIES_SHOW}/${category.unique_id}`}
              >
                <div className={clsx(styles['emoji-wrapper'])}>
                  {category.emoji}
                </div>{' '}
                {category.name}
              </a>
              <DropdownMenu
                items={[
                  {
                    text: 'Edit category',
                    icon: <PencilIcon />,
                    onClick: () => {
                      setCategoryToEdit({
                        name: category.name,
                        unique_id: category.unique_id,
                        emoji: category.emoji,
                      });
                      setShowEditCategoryDialog(true);
                    },
                  },
                  {
                    text: 'Delete category',
                    icon: <TrashIcon />,
                    onClick: () => {
                      setCategoryToDelete({
                        name: category.name,
                        unique_id: category.unique_id,
                      });
                      setShowDeleteCategoryDialog(true);
                    },
                    danger: true,
                  },
                ]}
              />
            </CardRow>
          ))}
        </Card>
      ) : null}
      <NewCategoryDialog
        open={showNewCategoryDialog}
        onOpenChange={setShowNewCategoryDialog}
        handleRefetch={fetchPostCategories}
      />
      <DeleteCategoryDialog
        open={showDeleteCategoryDialog}
        onOpenChange={() => {
          setCategoryToDelete({});
          setShowDeleteCategoryDialog(false);
        }}
        categoryToDelete={categoryToDelete}
        onSuccess={() => {
          fetchPostCategories();
          setCategoryToDelete({});
          setShowDeleteCategoryDialog(false);
        }}
      />
      <EditCategoryDialog
        open={showEditCategoryDialog}
        initialValues={categoryToEdit}
        onOpenChange={() => {
          setCategoryToEdit({});
          setShowEditCategoryDialog(false);
        }}
        onSuccess={() => {
          fetchPostCategories();
          setCategoryToEdit({});
          setShowEditCategoryDialog(false);
        }}
      />
    </PageLayout>
  );
}
