import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { PageLayout, Header, Button, EmptyState } from '../../../ui';
import { ProjectCard } from './components/ProjectCard';
import { NewProjectModal } from './components/NewProjectModal/NewProjectModal';

import { useRequest, useRouter } from '../../../hooks';

import styles from './ProjectsIndex.module.css';

export function ProjectsIndex() {
  const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);
  const { data, request, isLoading } = useRequest();

  const ROUTER = useRouter();

  const fetchProjects = () => {
    request({
      url: ROUTER.API.PROJECTS_INDEX,
    });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const hasNoProjects = !isLoading && !data?.length;

  return (
    <PageLayout className={styles.content}>
      <Header>
        <h1>Projects</h1>
        {data?.length ? (
          <Button
            label='New Project'
            onClick={() => setShowNewProjectDialog(true)}
          />
        ) : null}
      </Header>
      {hasNoProjects ? (
        <EmptyState>
          <h2>You don't have any projects yet</h2>
          <Button
            variant='secondary'
            label='Create a project'
            onClick={() => setShowNewProjectDialog(true)}
          />
        </EmptyState>
      ) : null}
      <div className={clsx(styles.grid)}>
        {data?.length
          ? data.map((project) => {
              return (
                <ROUTER.LINK
                  to={`${ROUTER.PATH.PROJECTS_BASE}/${project.unique_id}`}
                  key={project.unique_id}
                >
                  <ProjectCard project={project} />
                </ROUTER.LINK>
              );
            })
          : null}
      </div>

      <NewProjectModal
        open={showNewProjectDialog}
        onOpenChange={setShowNewProjectDialog}
        onSuccess={fetchProjects}
      />
    </PageLayout>
  );
}
