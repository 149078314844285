import React from 'react';
import clsx from 'clsx';

import styles from './Link.module.css';

export function Link({ href, className, target, children }) {
  return (
    <a href={href} className={clsx(styles.base, className)} target={target}>
      {children}
    </a>
  );
}
