import React from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from '../../../../../ui';

import { useRequest, useRouter } from '../../../../../hooks';

export function DeleteProjectModal({ open, onOpenChange }) {
  const { request } = useRequest();
  const ROUTER = useRouter();

  const deleteProject = () => {
    request({
      method: 'delete',
      url: ROUTER.API.PROJECTS_DELETE,
      onSuccess: () => {
        ROUTER.NAVIGATE(ROUTER.PATH.PROJECTS_INDEX);
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Delete project</h1>
      </DialogHeader>
      <DialogBody>
        <p>
          Are you sure you want to delete this project? Deleting a project will
          delete all data and files that belong to this project. This can't be
          undone.
        </p>
      </DialogBody>
      <DialogFooter>
        <Button variant='secondary' label='Cancel' onClick={onOpenChange} />
        <Button variant='danger' label='Delete' onClick={deleteProject} />
      </DialogFooter>
    </Dialog>
  );
}
