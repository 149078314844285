import React from 'react';

export function GoogleDocsLogo() {
  return (
    <svg
      width='18'
      height='24'
      viewBox='0 0 18 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8182 24H1.63636C0.736364 24 0 23.2636 0 22.3636V1.63636C0 0.736364 0.736364 0 1.63636 0H11.4545L17.4545 6V22.3636C17.4545 23.2636 16.7182 24 15.8182 24Z'
        fill='#3086F6'
      />
      <path d='M11.4541 0L17.4541 6H11.4541V0Z' fill='#0C67D6' />
      <path
        d='M13.6365 10.6371H3.81836V9.27344H13.6365V10.6371ZM13.6365 12.5462H3.81836V13.9098H13.6365V12.5462ZM10.9093 15.8189H3.81836V17.1825H10.9093V15.8189Z'
        fill='#FDFFFF'
      />
    </svg>
  );
}
