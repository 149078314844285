import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  Button,
  Card,
  CardRow,
  Label,
  Input,
  PageLayout,
  Header,
} from '../../../ui';

import { Editor } from '../../../features';
import { useRequest, useRouter } from '../../../hooks';

import styles from './PostsCreate.module.css';

export function PostsCreate() {
  const [editorContent, setEditorContent] = useState('');
  const [attachments, setAttachments] = useState('');

  const { register, watch } = useForm();
  const title = watch('title');

  const { request } = useRequest();
  const ROUTER = useRouter();

  return (
    <PageLayout>
      <Header>
        <h1>Create Post</h1>
      </Header>
      <Card>
        <CardRow className={clsx(styles['card-row'])}>
          <Label label='title' className='mb-8'>
            <Input register={register} name='title' />
          </Label>
          <Editor
            className='medium'
            getAttachments={setAttachments}
            setContent={setEditorContent}
          />
          <div className={clsx(styles.footer)}>
            <Button
              label='Cancel'
              variant='secondary'
              onClick={() => {
                ROUTER.NAVIGATE(ROUTER.PATH.POSTS_CATEGORIES_SHOW_POSTS);
              }}
            />
            <Button
              label='Create'
              onClick={() => {
                request({
                  url: ROUTER.API.POSTS_CREATE,
                  method: 'post',
                  data: {
                    title,
                    body: editorContent,
                    attachments,
                  },
                  onSuccess: (res) => {
                    ROUTER.NAVIGATE(
                      `${ROUTER.PATH.POSTS_SHOW}/${res.unique_id}`
                    );
                  },
                });
              }}
            />
          </div>
        </CardRow>
      </Card>
    </PageLayout>
  );
}
