import React from 'react';

export function FigmaLogo() {
  return (
    <svg
      width='16'
      height='24'
      viewBox='0 0 16 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_451_3205)'>
        <path
          d='M7.7998 12.0003C7.7998 9.81368 9.5281 8.04102 11.6601 8.04102C13.792 8.04102 15.5204 9.81368 15.5204 12.0003C15.5204 14.187 13.792 15.9596 11.6601 15.9596C9.5281 15.9596 7.7998 14.187 7.7998 12.0003Z'
          fill='#1ABCFE'
        />
        <path
          d='M0.0791016 19.9183C0.0791016 17.7317 1.80741 15.959 3.9394 15.959H7.79969V19.9183C7.79969 22.1049 6.0714 23.8775 3.9394 23.8775C1.80741 23.8775 0.0791016 22.1049 0.0791016 19.9183Z'
          fill='#0ACF83'
        />
        <path
          d='M7.7998 0.123047V8.04158H11.6601C13.7921 8.04158 15.5204 6.26897 15.5204 4.08232C15.5204 1.89568 13.7921 0.123047 11.6601 0.123047H7.7998Z'
          fill='#FF7262'
        />
        <path
          d='M0.0791016 4.08232C0.0791016 6.26897 1.80741 8.04158 3.9394 8.04158H7.79969V0.123047H3.9394C1.80741 0.123047 0.0791016 1.89567 0.0791016 4.08232Z'
          fill='#F24E1E'
        />
        <path
          d='M0.0791016 12.0003C0.0791016 14.187 1.80741 15.9596 3.9394 15.9596H7.79969V8.04102H3.9394C1.80741 8.04102 0.0791016 9.81368 0.0791016 12.0003Z'
          fill='#A259FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_451_3205'>
          <rect width='15.6' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
