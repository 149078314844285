import React from 'react';

export function GoogleSlidesLogo() {
  return (
    <svg
      width='48px'
      height='66px'
      viewBox='0 0 48 66'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-1'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-3'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-5'
        ></path>
        <linearGradient
          x1='50.0053945%'
          y1='8.58610612%'
          x2='50.0053945%'
          y2='100.013939%'
          id='linearGradient-7'
        >
          <stop stopColor='#BF360C' stopOpacity='0.2' offset='0%'></stop>
          <stop stopColor='#BF360C' stopOpacity='0.02' offset='100%'></stop>
        </linearGradient>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-8'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-10'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-12'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-14'
        ></path>
        <path
          d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
          id='path-16'
        ></path>
        <radialGradient
          cx='3.16804688%'
          cy='2.71744318%'
          fx='3.16804688%'
          fy='2.71744318%'
          r='161.248516%'
          gradientTransform='translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)'
          id='radialGradient-18'
        >
          <stop stopColor='#FFFFFF' stopOpacity='0.1' offset='0%'></stop>
          <stop stopColor='#FFFFFF' stopOpacity='0' offset='100%'></stop>
        </radialGradient>
      </defs>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Consumer-Apps-Slides-Large-VD-R8'
          transform='translate(-449.000000, -452.000000)'
        >
          <g id='Hero' transform='translate(0.000000, 63.000000)'>
            <g id='Personal' transform='translate(277.000000, 299.000000)'>
              <g id='Slides-icon' transform='translate(172.000000, 90.000000)'>
                <g id='Group'>
                  <g id='Clipped'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <path
                      d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z'
                      id='Path'
                      fill='#F4B400'
                      fillRule='nonzero'
                      mask='url(#mask-2)'
                    ></path>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-4' fill='white'>
                      <use xlinkHref='#path-3'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <path
                      d='M33.28125,29.5833333 L14.0520833,29.5833333 C12.8317708,29.5833333 11.8333333,30.5817708 11.8333333,31.8020833 L11.8333333,51.03125 C11.8333333,52.2515625 12.8317708,53.25 14.0520833,53.25 L33.28125,53.25 C34.5015625,53.25 35.5,52.2515625 35.5,51.03125 L35.5,31.8020833 C35.5,30.5817708 34.5015625,29.5833333 33.28125,29.5833333 Z M32.5416667,46.59375 L14.7916667,46.59375 L14.7916667,36.2395833 L32.5416667,36.2395833 L32.5416667,46.59375 Z'
                      id='Shape'
                      fill='#F1F1F1'
                      fillRule='nonzero'
                      mask='url(#mask-4)'
                    ></path>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-6' fill='white'>
                      <use xlinkHref='#path-5'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <polygon
                      id='Path'
                      fill='url(#linearGradient-7)'
                      fillRule='nonzero'
                      mask='url(#mask-6)'
                      points='30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75'
                    ></polygon>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-9' fill='white'>
                      <use xlinkHref='#path-8'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <g id='Group' mask='url(#mask-9)'>
                      <g transform='translate(26.625000, -2.958333)'>
                        <path
                          d='M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z'
                          id='Path'
                          fill='#FADA80'
                          fillRule='nonzero'
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-11' fill='white'>
                      <use xlinkHref='#path-10'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <polygon
                      id='Path'
                      fillOpacity='0.1'
                      fill='#FFFFFF'
                      fillRule='nonzero'
                      mask='url(#mask-11)'
                      points='29.5833333 0 29.5833333 0.369791667 46.9635417 17.75 47.3333333 17.75'
                    ></polygon>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-13' fill='white'>
                      <use xlinkHref='#path-12'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <path
                      d='M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z'
                      id='Path'
                      fillOpacity='0.2'
                      fill='#FFFFFF'
                      fillRule='nonzero'
                      mask='url(#mask-13)'
                    ></path>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-15' fill='white'>
                      <use xlinkHref='#path-14'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <path
                      d='M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z'
                      id='Path'
                      fillOpacity='0.2'
                      fill='#BF360C'
                      fillRule='nonzero'
                      mask='url(#mask-15)'
                    ></path>
                  </g>
                  <g id='Clipped'>
                    <mask id='mask-17' fill='white'>
                      <use xlinkHref='#path-16'></use>
                    </mask>
                    <g id='SVGID_1_'></g>
                    <path
                      d='M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642188 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z'
                      id='Path'
                      fillOpacity='0.1'
                      fill='#BF360C'
                      fillRule='nonzero'
                      mask='url(#mask-17)'
                    ></path>
                  </g>
                </g>
                <path
                  d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
                  id='Path'
                  fill='url(#radialGradient-18)'
                  fillRule='nonzero'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
