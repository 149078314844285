import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import { PageLayout, Header, Button } from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';
import { NewEventModal } from './components/NewEventModal';

import { formatEvents } from './utils/formatEvents';

export function CalendarIndex(): React.ReactNode {
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  const { data, request } = useRequest();
  const ROUTER = useRouter();

  const fetchEvents = () => {
    request({
      url: ROUTER.API.EVENTS_INDEX,
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <PageLayout>
      <Header>
        <h1>Calendar</h1>
        <Button label='New Event' onClick={() => setShowNewEventModal(true)} />
      </Header>
      <NewEventModal
        isOpen={showNewEventModal}
        setIsOpen={setShowNewEventModal}
        refetchEvents={fetchEvents}
      />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        events={formatEvents(data)}
        eventClick={() => {}}
      />
    </PageLayout>
  );
}
