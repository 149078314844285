import React from 'react';

export function GoogleSheetsLogo() {
  return (
    <svg
      width='18'
      height='24'
      viewBox='0 0 18 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_451_3248)'>
        <path
          d='M11.4546 0L17.4546 6L14.4546 6.54545L11.4546 6L10.9092 3L11.4546 0Z'
          fill='#188038'
        />
        <path
          d='M11.4545 6V0H1.63636C0.732273 0 0 0.732273 0 1.63636V22.3636C0 23.2677 0.732273 24 1.63636 24H15.8182C16.7223 24 17.4545 23.2677 17.4545 22.3636V6H11.4545Z'
          fill='#34A853'
        />
        <path
          d='M3.27246 9.27344V17.1825H14.1816V9.27344H3.27246ZM8.04519 15.8189H4.6361V13.9098H8.04519V15.8189ZM8.04519 12.5462H4.6361V10.6371H8.04519V12.5462ZM12.8179 15.8189H9.40882V13.9098H12.8179V15.8189ZM12.8179 12.5462H9.40882V10.6371H12.8179V12.5462Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_451_3248'>
          <rect width='17.4545' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
