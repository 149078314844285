import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import * as Checkbox from '@radix-ui/react-checkbox';

import { DropdownMenu, Header, PageLayout, PencilIcon } from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';
import { EditTask } from './components/EditTask';
import { ReadTask } from './components/ReadTask';

import styles from './TasksShow.module.css';

export function TasksShow() {
  const [isEditing, setIsEditing] = useState(false);
  const [task, setTask] = useState({ name: '' });

  const { request } = useRequest();
  const ROUTER = useRouter();

  const fetchTask = () => {
    request({
      url: ROUTER.API.TASKS_SHOW,
      method: 'get',
      onSuccess: (payload) => {
        setTask(payload);
      },
    });
  };

  useEffect(() => {
    fetchTask();
  }, []);

  return (
    <PageLayout>
      <Header>
        <div className={clsx(styles['checkbox-wrapper'])}>
          <Checkbox.Root
            className={clsx(styles['checkbox-root'])}
            checked={task?.completed}
            onCheckedChange={(e) => {
              request({
                url: `${ROUTER.API.TASK_STATUS}/${task.unique_id}`,
                method: 'put',
                data: { completed: e },
                onSuccess: fetchTask,
              });
            }}
            id='c1'
          >
            <Checkbox.Indicator className={clsx(styles['checkbox-indicator'])}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.5 12.5L10.0168 17.7247L10.4177 17.0238C12.5668 13.2658 15.541 10.0448 19.1161 7.60354L20 7'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Checkbox.Indicator>
          </Checkbox.Root>
          {isEditing ? (
            <input
              type='text'
              defaultValue={task.name}
              onChange={(e) => setTask({ ...task, name: e.target.value })}
              className={clsx(styles['title-input'])}
            />
          ) : (
            <h1>{task.name}</h1>
          )}
        </div>

        <DropdownMenu
          items={[
            {
              text: 'Edit',
              icon: <PencilIcon />,
              onClick: () => {
                setIsEditing(true);
              },
            },
          ]}
        />
      </Header>

      {isEditing ? (
        <EditTask
          task={task}
          handleIsEditing={setIsEditing}
          onSuccess={fetchTask}
        />
      ) : (
        <ReadTask task={task} />
      )}
    </PageLayout>
  );
}
