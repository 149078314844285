import React, { useEffect } from 'react';
import clsx from 'clsx';

import { useRequest, useRouter } from '../../hooks';

import styles from './Breadcrumbs.module.css';

export function Breadcrumbs() {
  const ROUTER = useRouter();
  const { data, request, isLoading } = useRequest();
  const params = ROUTER.USE_PARAMS();

  useEffect(() => {
    request({ url: `/api/v1/navigation`, params });
  }, [params]);

  if (!params.projectUniqueId) {
    return null;
  }

  return (
    <div className={clsx(styles.base)}>
      {data?.project_unique_id && (
        <ROUTER.LINK
          to={`/${data?.organization_slug}/projects`}
          className={clsx(styles.link)}
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2 9.4C2 7.15979 2 6.03968 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03968 3 6.15979 3 8.4 3H8.71556C9.18517 3 9.41997 3 9.6331 3.06473C9.82179 3.12203 9.99732 3.21597 10.1497 3.34118C10.3217 3.48262 10.452 3.67798 10.7125 4.06872L11.2875 4.93128C11.548 5.32202 11.6783 5.51739 11.8503 5.65882C12.0027 5.78403 12.1782 5.87797 12.3669 5.93527C12.58 6 12.8148 6 13.2844 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03969 22 10.1598 22 12.4V14.6C22 16.8402 22 17.9603 21.564 18.816C21.1805 19.5686 20.5686 20.1805 19.816 20.564C18.9603 21 17.8402 21 15.6 21H8.4C6.15979 21 5.03968 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4Z'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Projects
        </ROUTER.LINK>
      )}

      {data?.project_name && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}`}
            className={clsx(styles.link)}
          >
            <span className={styles['project-emoji']}>
              {data?.project_emoji}
            </span>
            {data?.project_name}
          </ROUTER.LINK>
        </>
      )}

      {/* Checklist Unique */}
      {data?.checklist_name && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/checklists`}
            className={clsx(styles.link)}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8.5 12.5124L10.8412 14.851C11.9672 12.8821 13.5256 11.1944 15.3987 9.91536L15.5 9.84619M12 21C9.20435 21 7.80653 21 6.7039 20.5433C5.23373 19.9343 4.06569 18.7663 3.45672 17.2961C3 16.1935 3 14.7956 3 12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Checklists
          </ROUTER.LINK>

          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/checklists`}
            className={clsx(styles.link)}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8.5 12.5124L10.8412 14.851C11.9672 12.8821 13.5256 11.1944 15.3987 9.91536L15.5 9.84619M12 21C9.20435 21 7.80653 21 6.7039 20.5433C5.23373 19.9343 4.06569 18.7663 3.45672 17.2961C3 16.1935 3 14.7956 3 12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            {data?.checklist_name}
          </ROUTER.LINK>
        </>
      )}

      {/* Event */}

      {data?.event_unique_id && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/calendar`}
            className={clsx(styles.link)}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M20.9597 10C21 10.7878 21 11.7554 21 13C21 15.7956 21 17.1935 20.5433 18.2961C19.9343 19.7663 18.7663 20.9343 17.2961 21.5433C16.1935 22 14.7956 22 12 22C9.20435 22 7.80653 22 6.7039 21.5433C5.23373 20.9343 4.06569 19.7663 3.45672 18.2961C3 17.1935 3 15.7956 3 13C3 11.7554 3 10.7878 3.0403 10M20.9597 10C20.9095 9.01824 20.7967 8.31564 20.5433 7.7039C19.9343 6.23373 18.7663 5.06569 17.2961 4.45672C16.9146 4.29871 16.4978 4.19536 16 4.12777M20.9597 10H3.0403M3.0403 10C3.09052 9.01824 3.20333 8.31564 3.45672 7.7039C4.06569 6.23373 5.23373 5.06569 6.7039 4.45672C7.08538 4.29871 7.50219 4.19536 8 4.12777M16 4.12777C15.0589 4 13.8284 4 12 4C10.1716 4 8.94106 4 8 4.12777M16 4.12777V2M16 4.12777V6M8 4.12777V2M8 4.12777V6M8.01 14H8M8.01 18H8M12.01 14H12M12.01 18H12M16.01 14H16M16.01 18H16'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Calendar
          </ROUTER.LINK>
        </>
      )}
      {data?.post_category_unique_id && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/posts`}
            className={clsx(styles.link)}
          >
            <svg
              width='22'
              height='22'
              viewBox='0 0 22 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19 13C19 15.8003 19 17.2004 18.455 18.27C17.9757 19.2108 17.2108 19.9757 16.27 20.455C15.2004 21 13.8003 21 11 21H9C6.19974 21 4.79961 21 3.73005 20.455C2.78924 19.9757 2.02433 19.2108 1.54497 18.27C1 17.2004 1 15.8003 1 13V11C1 8.19974 1 6.79961 1.54497 5.73005C2.02433 4.78924 2.78924 4.02433 3.73005 3.54497C4.79961 3 6.19974 3 9 3M7.06156 12.5015C7.07022 12.1508 7.07455 11.9754 7.11695 11.8106C7.15455 11.6645 7.21409 11.5251 7.29353 11.397C7.38312 11.2526 7.50663 11.1285 7.75366 10.8804L17.1499 1.43082C17.6371 0.941448 18.3981 0.859448 18.9776 1.23386C19.6646 1.67773 20.2516 2.261 20.7008 2.94623C20.726 2.98471 20.7519 3.02286 20.7761 3.06196C21.1519 3.66727 21.0293 4.43321 20.536 4.92861L11.2137 14.304C10.9574 14.5614 10.8293 14.6901 10.6798 14.782C10.5472 14.8635 10.4026 14.9235 10.2514 14.9598C10.0809 15.0007 9.8997 15.0005 9.5373 14.9999L7 14.9958L7.06156 12.5015Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Posts
          </ROUTER.LINK>
        </>
      )}

      {data?.post_unique_id && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/posts/categories/${data?.post_category_unique_id}`}
            className={clsx(styles.link)}
          >
            <span className={styles['project-emoji']}>
              {data?.post_category_emoji}
            </span>
            {data?.post_category_name}
          </ROUTER.LINK>
        </>
      )}

      {data?.file_unique_id && (
        <>
          <span className={clsx(styles.chevron)}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1C2.46188 2.06206 3.77128 3.30708 4.8942 4.70212C5.03527 4.87737 5.03527 5.12263 4.8942 5.29788C3.77128 6.69292 2.46188 7.93794 1 9'
                stroke='#111111'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <ROUTER.LINK
            to={`/${data?.organization_slug}/projects/${data?.project_unique_id}/files/`}
            className={clsx(styles.link)}
          >
            <svg
              width='18'
              height='22'
              viewBox='0 0 18 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M17 10C17 8.34315 15.6569 7 14 7H13.4C13.0284 7 12.8426 7 12.6871 6.97538C11.8313 6.83983 11.1602 6.16865 11.0246 5.31287C11 5.1574 11 4.9716 11 4.6V4C11 2.34315 9.6569 1 8 1M17 9V17C17 19.2091 15.2091 21 13 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1H9C13.4183 1 17 4.58172 17 9Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Files
          </ROUTER.LINK>
        </>
      )}
    </div>
  );
}
