import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Card, CardRow, Attachment } from '../../../../ui';

import styles from './AttachmentsList.module.css';

export function AttachmentsList({ attachments, getAttachments, isUploading }) {
  // Source of truth for attachments that have been added or removed
  const [_attachments, _setAttachments] = useState(attachments);

  useEffect(() => {
    getAttachments(_attachments);
  }, [_attachments]);

  if (attachments.length === 0 && !isUploading) {
    return null;
  }

  return (
    <Card className={clsx(styles.attachments)}>
      {attachments.map((attachment) => (
        <Attachment
          key={attachment.url}
          filename={attachment.original_filename}
          size={attachment.size}
          url={attachment.url}
          setAttachments={() => {
            // When attachments get deleted,
            // update list of attachments
            const updatedAttachments = attachments.filter(
              (attached) => attached.url !== attachment.url
            );
            _setAttachments(updatedAttachments);
          }}
        />
      ))}
      {isUploading && (
        <CardRow className={clsx(styles['card-row'])}>
          <span className={clsx(styles.loader)}></span>
          <span>Uploading file...</span>
        </CardRow>
      )}
    </Card>
  );
}
