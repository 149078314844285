import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  Header,
  Card,
  CardRow,
  CalendarPreview,
  Label,
  DatePicker,
  Button,
  Input,
  PageLayout,
} from '../../../ui';
import { Editor } from '../../../features/Editor';
import { useRequest, useRouter } from '../../../hooks';

import styles from './EventsEdit.module.css';

export function EventsEdit() {
  const { data, request } = useRequest();
  const { data: updateData, request: updateRequest } = useRequest();

  const [title, setTitle] = useState(data?.title);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [notes, setNotes] = useState(data?.notes);
  const [attachments, setAttachments] = useState([]);

  const ROUTER = useRouter();

  const fetchEvent = () => {
    request({
      url: ROUTER.API.EVENTS_SHOW,
      onSuccess: (data) => {
        if (data.start_at) {
          setStart(data.start_at);
        }

        if (data.end_at) {
          setEnd(data.end_at);
        }
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <PageLayout>
      <Header>
        <Label label='Title'>
          <Input
            register={() => {}}
            name='title'
            defaultValue={data?.title}
            onChange={setTitle}
          />
        </Label>
      </Header>
      <Card className={clsx(styles.card)}>
        <CardRow className={clsx(styles['card-row'])}>
          <div className={clsx(styles['calendar-wrapper'])}>
            <CalendarPreview date={start} />
          </div>

          <Label label='Starts'>
            <DatePicker name='starts' initialDate={start} onChange={setStart} />
          </Label>
        </CardRow>

        <CardRow className={clsx(styles['card-row'])}>
          <div className={clsx(styles['calendar-wrapper'])}>
            <CalendarPreview date={end} />
          </div>

          <Label label='Ends'>
            <DatePicker name='ends' initialDate={end} onChange={setEnd} />
          </Label>
        </CardRow>
        <CardRow className={clsx(styles['card-row'])}>
          <div className={clsx(styles['editor-wrapper'])}>
            <Label label='Notes'></Label>
            <Editor
              defaultValue={data?.notes}
              setContent={setNotes}
              getAttachments={setAttachments}
              initialAttachments={data?.attachments}
            />
          </div>
        </CardRow>
        <CardRow className={clsx(styles['card-row'], styles.footer)}>
          <Button
            label='Discard changes'
            variant='secondary'
            onClick={() => {
              ROUTER.NAVIGATE(ROUTER.PATH.EVENT_SHOW);
            }}
          />
          <Button
            label='Update'
            disabled={!start}
            onClick={() => {
              updateRequest({
                url: ROUTER.API.EVENTS_UPDATE,
                method: 'put',
                data: {
                  title: title || data?.title || 'Untitled Event',
                  start_at: start,
                  end_at: end,
                  notes: notes || data?.notes,
                  event_unique_id: ROUTER.PARAMS.EVENT_UNIQUE_ID,
                  attachments,
                },
                onSuccess: () => {
                  ROUTER.NAVIGATE(ROUTER.PATH.EVENT_SHOW);
                },
                onError: () => {},
              });
            }}
          />
        </CardRow>
      </Card>
    </PageLayout>
  );
}
