import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';

import styles from './SelectItem.module.css';

export const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        className={clsx(styles.base, className)}
        {...props}
        ref={forwardedRef}
      >
        <SelectPrimitive.ItemIndicator className='SelectItemIndicator'>
          <svg
            width='17'
            height='13'
            viewBox='0 0 17 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.5 6.5L6.0168 11.7247L6.4177 11.0238C8.5668 7.2658 11.541 4.0448 15.1161 1.60354L16 1'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </SelectPrimitive.ItemIndicator>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  }
);
