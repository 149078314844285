import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import * as DialogPrimative from '@radix-ui/react-dialog';

import styles from './Dialog.module.css';

export function Dialog({
  open,
  onOpenChange,
  children,
  className,
  size = 'md',
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  className?: string;
  size?: string;
}): React.ReactNode {
  return (
    <DialogPrimative.Root
      open={open}
      onOpenChange={() => onOpenChange(false)}
      id='dialog-root'
    >
      <DialogPrimative.Trigger asChild></DialogPrimative.Trigger>
      <DialogPrimative.Portal>
        <DialogPrimative.Overlay className={clsx(styles.overlay)} />
        <DialogPrimative.Content
          className={clsx(styles.base, styles.content, styles[size], className)}
        >
          <DialogPrimative.Close asChild>
            <button className={clsx(styles['close-button'])}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M18 6 6 18' />
                <path d='m6 6 12 12' />
              </svg>
            </button>
          </DialogPrimative.Close>
          {children}
        </DialogPrimative.Content>
      </DialogPrimative.Portal>
    </DialogPrimative.Root>
  );
}
