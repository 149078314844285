import React, { useState } from 'react';
import clsx from 'clsx';

import { Button, Card, CardRow, SectionHeader } from '../../../../../ui';
import { Editor } from '../../../../../features';
import { useRequest, useRouter } from '../../../../../hooks';

import styles from './EditPost.module.css';

export function EditPost({ handleIsEditing, post, handleRefetch }) {
  const [updatedBody, setUpdatedBody] = useState(post.body);
  const [_attachments, _setAttachments] = useState([]);

  const { request } = useRequest();
  const ROUTER = useRouter();

  const onSubmitUpdate = () => {
    request({
      url: ROUTER.API.POSTS_UPDATE,
      method: 'put',
      data: {
        body: updatedBody,
        attachments: _attachments,
      },
      onSuccess: () => {
        handleIsEditing(false);
        handleRefetch();
      },
    });
  };

  return (
    <Card>
      <CardRow className={clsx(styles['card-row'], styles['editor-row'])}>
        <SectionHeader text='Description' />

        <Editor
          defaultValue={updatedBody}
          setContent={setUpdatedBody}
          getAttachments={_setAttachments}
          initialAttachments={post.attachments}
          className='medium'
        />
      </CardRow>

      <CardRow className={clsx(styles['card-row'], styles.footer)}>
        <div className={clsx(styles.actions)}>
          <Button
            variant='secondary'
            label='Discard Changes'
            onClick={() => handleIsEditing(false)}
          />

          <Button label='Save Changes' onClick={onSubmitUpdate} />
        </div>
      </CardRow>
    </Card>
  );
}
