import React from 'react';
import clsx from 'clsx';

import styles from './DialogHeader.module.css';

export function DialogHeader({
  children,
  border = true,
}: {
  children: React.ReactNode;
  border: boolean;
}): React.ReactNode {
  return (
    <div className={clsx(styles.base, border && styles['border'])}>
      {children}
    </div>
  );
}
