import React, { useEffect, useState, useContext } from 'react';

import { AccountContext } from '../../contexts/account';
import { useRequest, useRouter } from '../../../src/hooks';

import { Error } from './components/error';

export function Permissions({ children }) {
  const [pageError, setPageError] = useState(null);

  const { request, isLoading } = useRequest();

  const ROUTER = useRouter();

  const account = useContext(AccountContext);

  const params = ROUTER.USE_PARAMS();

  const excludedPaths = ['/onboarding', '/logout'];

  useEffect(() => {
    // Skip permissions check on onboarding page
    if (excludedPaths.includes(window.location.pathname)) {
      return;
    }

    // ORGANIZATIONS
    if (params.organizationSlug) {
      request({
        url: ROUTER.API.ORGANIZATIONS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // PROJECTS
    if (params.projectUniqueId) {
      request({
        url: ROUTER.API.PROJECTS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // TASKS
    if (params.taskUniqueId) {
      request({
        url: ROUTER.API.TASKS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // EVENTS
    if (params.eventUniqueId) {
      request({
        url: ROUTER.API.EVENTS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // POSTS
    if (params.postUniqueId) {
      request({
        url: ROUTER.API.POSTS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // POST CATEGORY
    if (params.postCategoryUniqueId) {
      request({
        url: ROUTER.API.CATEGORY_POSTS_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }

    // FILES
    if (params.fileUniqueId) {
      request({
        url: ROUTER.API.FILES_SHOW,
        onError: (error) => {
          setPageError(error);
        },
      });
    }
  }, []);

  if (isLoading || !account) {
    return null;
  }

  if (pageError) {
    return <Error error={pageError?.error} />;
  }

  return <>{children}</>;
}
