import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import clsx from 'clsx';

import styles from './RadioGroupItem.module.css';

export const RadioGroupItem = ({ name, value }) => (
  <div className={clsx(styles.base)}>
    <RadioGroupPrimitive.Item
      className={clsx(styles.radio)}
      value={value}
      id={value}
    >
      <RadioGroupPrimitive.Indicator className={clsx(styles.indicator)} />
    </RadioGroupPrimitive.Item>
    <label className='Label' htmlFor={value}>
      <span>{name}</span>
    </label>
  </div>
);
