import React from 'react';
import clsx from 'clsx';

import styles from './CalendarPreview.module.css';

import { getMonthName } from '../../../utils/dates/getMonthName';
import { getDay } from '../../../utils/dates/getDay';

export function CalendarPreview({ date }) {
  return (
    <div className={clsx(styles.base)}>
      <div className={clsx(styles.month)}>
        <span className={clsx(styles['month-name'])}>{getMonthName(date)}</span>
      </div>
      <div className={clsx(styles.day)}>
        <span className={clsx(styles['day-number'])}>{getDay(date)}</span>
      </div>
    </div>
  );
}
