import React from 'react';
import clsx from 'clsx';

import { Card } from '../../../../ui';

import styles from './PageCard.module.css';

export function PageCard({ icon = null, pageName = '' }) {
  return (
    <Card className={clsx(styles['card-base'])}>
      <div className={clsx(styles.body)}>
        <div className={clsx(styles.icon)}>{icon}</div>
        <h2 className={clsx(styles['page-name'])}>{pageName}</h2>
      </div>
    </Card>
  );
}
