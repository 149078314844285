import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { AccountContext } from '../../../../contexts/account';

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Label,
  Input,
  RadioGroup,
  RadioGroupItem,
} from '../../../../ui';

import { useRequest, useRouter } from '../../../../hooks';

import styles from './InvitePeopleDialog.module.css';

export function InvitePeopleDialog({
  open,
  onClose,
  handleRefetch,
}: {
  open: boolean;
  onClose: () => void;
  handleRefetch: () => void;
}) {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { request, isLoading } = useRequest();
  const ROUTER = useRouter();

  const [role, setRole] = useState('');
  const email = watch('email');

  const { default_organization } = useContext(AccountContext);

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: ROUTER.API.INVITE_USER_BASE,
      data: {
        email: data.email,
        organization_slug: ROUTER.PARAMS.ORGANIZATION_SLUG,
        role,
      },
      onSuccess: () => {
        reset();
        handleRefetch();
        onClose();
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <h1>Invite People</h1>
        </DialogHeader>
        <DialogBody>
          {default_organization?.has_active_subscription && (
            <div className={styles.warning}>
              <svg
                width='22'
                height='18'
                viewBox='0 0 22 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 6H21M5 10H8M14.6 17H7.4C5.15979 17 4.03969 17 3.18404 16.564C2.43139 16.1805 1.81947 15.5686 1.43597 14.816C1 13.9603 1 12.8402 1 10.6V7.4C1 5.15979 1 4.03968 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H14.6C16.8402 1 17.9603 1 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C21 4.03968 21 5.15979 21 7.4V10.6C21 12.8402 21 13.9603 20.564 14.816C20.1805 15.5686 19.5686 16.1805 18.816 16.564C17.9603 17 16.8402 17 14.6 17Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <p>
                <strong>Hey, just a heads up!</strong> You're currently on a{' '}
                <strong>$12 per user</strong>, monthly subscription plan.
                Inviting a new user will increase your total monthly cost from{' '}
                <strong>${12 * default_organization.user_count}</strong> to{' '}
                <strong>${12 * (default_organization.user_count + 1)}</strong>.
              </p>
            </div>
          )}
          <Label name='email' label='Email'>
            <Input register={register} name='email' />
          </Label>
          <Label name='role' label='Role' />
          <RadioGroup onChange={(e) => setRole(e.target.value)}>
            <RadioGroupItem name='Admin' value='admin' />
            <RadioGroupItem name='Member' value='member' />
          </RadioGroup>
        </DialogBody>
        <DialogFooter>
          <Button label='Close' onClick={onClose} variant='secondary' />
          <Button
            label={isLoading ? 'Inviting...' : 'Invite'}
            disabled={isLoading || !email || !role}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
