import React from 'react';
import clsx from 'clsx';

import styles from './Input.module.css';

export function Input({
  name,
  onBlur,
  onKeyDown,
  placeholder,
  register,
  required = false,
  defaultValue,
  onChange = () => {},
}) {
  return (
    <input
      defaultValue={defaultValue}
      className={clsx(styles.base)}
      id='name'
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      type='text'
      {...register(name, { required })}
    />
  );
}
