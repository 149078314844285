import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogBody,
  Input,
  Label,
} from '../../../../../ui';
import { Editor } from '../../../../../features';

import { useRequest, useRouter } from '../../../../../hooks';

export function FileDialog({
  open,
  onOpenChange,
  serviceName,
  isEditing = false,
  file = {},
  handleRefetch = () => {},
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  serviceName?: string;
  isEditing?: boolean;
  file?: any;
  handleRefetch: () => void;
}) {
  const [editorContent, setEditorContent] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { request } = useRequest();
  const { request: updateRequest } = useRequest();
  const ROUTER = useRouter();

  const onSubmit = (data) => {
    if (isEditing) {
      updateRequest({
        method: 'put',
        url: ROUTER.API.FILES_UPDATE,
        data: {
          ...data,
          notes: editorContent,
        },
        onSuccess: () => {
          handleRefetch();
          closeDialog();
        },
        onError: () => {},
      });
    } else {
      request({
        method: 'post',
        url: ROUTER.API.FILES_CREATE,
        data: {
          ...data,
          notes: editorContent,
          service_name: serviceName,
        },
        onSuccess: () => {
          ROUTER.NAVIGATE(ROUTER.PATH.FILES_INDEX);
        },
        onError: () => {},
      });
    }
  };

  const closeDialog = () => {
    onOpenChange();
    reset();
  };

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <h1>
            {isEditing ? 'Edit' : 'Add'} {serviceName} File
          </h1>
        </DialogHeader>
        <DialogBody>
          <Label label='Name'>
            <Input
              register={register}
              name='name'
              defaultValue={file?.custom_name}
            />
          </Label>

          <Label label='link'>
            <Input register={register} name='link' defaultValue={file?.url} />
          </Label>

          <Label label='Notes' />

          <Editor
            hideFeatures={['attachments']}
            setContent={setEditorContent}
            className='small'
            defaultValue={file?.notes}
          />
        </DialogBody>
        <DialogFooter>
          <Button label='Cancel' variant='secondary' onClick={closeDialog} />
          <Button label={isEditing ? 'Update File' : 'Add File'} />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
