import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { SelectItem } from './components/SelectItem';

const ROLES = {
  member: 'Member',
  admin: 'Admin',
};

import styles from './Select.module.css';
import clsx from 'clsx';

export const Select = ({ value = '', children, onChange }) => (
  <SelectPrimitive.Root onValueChange={onChange}>
    <SelectPrimitive.Trigger className={clsx(styles.trigger)}>
      <SelectPrimitive.Value
        placeholder={ROLES[value] || ''}
        className={clsx(styles.value)}
      />{' '}
      <svg
        width='11'
        height='7'
        viewBox='0 0 11 7'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.5 1.13916C2.56206 2.60096 3.8071 3.91036 5.2021 5.03336C5.3774 5.17436 5.6226 5.17436 5.7979 5.03336C7.1929 3.91036 8.4379 2.60096 9.5 1.13916'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SelectPrimitive.Trigger>
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content className={clsx(styles.content)}>
        <SelectPrimitive.Viewport className='SelectViewport'>
          <SelectPrimitive.Group>{children}</SelectPrimitive.Group>
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  </SelectPrimitive.Root>
);
