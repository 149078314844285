import React, { useState } from 'react';
import clsx from 'clsx';

import { Card, PageLayout, Header } from '../../../ui';
import { FileDialog } from '../_shared/components/FileDialog';
import styles from './FilesNew.module.css';

const fileTypes = [
  {
    name: 'Dropbox',
    logo: (
      <svg
        // width='26'
        // height='22'
        viewBox='0 0 26 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.43695 0.0332031L0 4.16485L6.43695 8.23573L12.8739 4.16485L6.43695 0.0332031ZM19.3108 0.0332031L12.8739 4.16485L19.3108 8.23573L25.7478 4.16485L19.3108 0.0332031ZM0 12.3674L6.43695 16.499L12.8739 12.3674L6.43695 8.23573L0 12.3674ZM19.3108 8.23573L12.8739 12.3674L19.3108 16.499L25.7478 12.3674L19.3108 8.23573ZM6.43695 17.8357L12.8739 21.9674L19.3108 17.8357L12.8739 13.7648L6.43695 17.8357Z'
          fill='#0062FF'
        />
      </svg>
    ),
  },
  {
    name: 'Figma',
    logo: (
      <svg
        // width='16'
        // height='24'
        viewBox='0 0 16 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_451_3205)'>
          <path
            d='M7.7998 12.0003C7.7998 9.81368 9.5281 8.04102 11.6601 8.04102C13.792 8.04102 15.5204 9.81368 15.5204 12.0003C15.5204 14.187 13.792 15.9596 11.6601 15.9596C9.5281 15.9596 7.7998 14.187 7.7998 12.0003Z'
            fill='#1ABCFE'
          />
          <path
            d='M0.0791016 19.9183C0.0791016 17.7317 1.80741 15.959 3.9394 15.959H7.79969V19.9183C7.79969 22.1049 6.0714 23.8775 3.9394 23.8775C1.80741 23.8775 0.0791016 22.1049 0.0791016 19.9183Z'
            fill='#0ACF83'
          />
          <path
            d='M7.7998 0.123047V8.04158H11.6601C13.7921 8.04158 15.5204 6.26897 15.5204 4.08232C15.5204 1.89568 13.7921 0.123047 11.6601 0.123047H7.7998Z'
            fill='#FF7262'
          />
          <path
            d='M0.0791016 4.08232C0.0791016 6.26897 1.80741 8.04158 3.9394 8.04158H7.79969V0.123047H3.9394C1.80741 0.123047 0.0791016 1.89567 0.0791016 4.08232Z'
            fill='#F24E1E'
          />
          <path
            d='M0.0791016 12.0003C0.0791016 14.187 1.80741 15.9596 3.9394 15.9596H7.79969V8.04102H3.9394C1.80741 8.04102 0.0791016 9.81368 0.0791016 12.0003Z'
            fill='#A259FF'
          />
        </g>
        <defs>
          <clipPath id='clip0_451_3205'>
            <rect width='15.6' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: 'Google Docs',
    logo: (
      <svg
        // width='18'
        // height='24'
        viewBox='0 0 18 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.8182 24H1.63636C0.736364 24 0 23.2636 0 22.3636V1.63636C0 0.736364 0.736364 0 1.63636 0H11.4545L17.4545 6V22.3636C17.4545 23.2636 16.7182 24 15.8182 24Z'
          fill='#3086F6'
        />
        <path d='M11.4541 0L17.4541 6H11.4541V0Z' fill='#0C67D6' />
        <path
          d='M13.6365 10.6371H3.81836V9.27344H13.6365V10.6371ZM13.6365 12.5462H3.81836V13.9098H13.6365V12.5462ZM10.9093 15.8189H3.81836V17.1825H10.9093V15.8189Z'
          fill='#FDFFFF'
        />
      </svg>
    ),
  },
  {
    name: 'Google Drive',
    logo: (
      <svg
        // width='25'
        // height='22'
        viewBox='0 0 25 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_451_3224)'>
          <path
            d='M1.86154 18.8556L2.94744 20.7313C3.17308 21.1261 3.49744 21.4364 3.87821 21.662L7.75641 14.9492H0C0 15.3864 0.112821 15.8236 0.338462 16.2184L1.86154 18.8556Z'
            fill='#0066DA'
          />
          <path
            d='M12.3115 7.05071L8.43333 0.337891C8.05256 0.563532 7.72821 0.873788 7.50256 1.26866L0.338462 13.6789C0.11697 14.0653 0.000294902 14.5028 0 14.9481H7.75641L12.3115 7.05071Z'
            fill='#00AC47'
          />
          <path
            d='M20.745 21.662C21.1258 21.4364 21.4501 21.1261 21.6757 20.7313L22.127 19.9556L24.2847 16.2184C24.5104 15.8236 24.6232 15.3864 24.6232 14.9492H16.8662L18.5168 18.1928L20.745 21.662Z'
            fill='#EA4335'
          />
          <path
            d='M12.3118 7.05128L16.19 0.338462C15.8092 0.112821 15.3721 0 14.9208 0H9.70283C9.25154 0 8.81436 0.126923 8.43359 0.338462L12.3118 7.05128Z'
            fill='#00832D'
          />
          <path
            d='M16.8664 14.9492H7.75613L3.87793 21.662C4.2587 21.8877 4.69588 22.0005 5.14716 22.0005H19.4754C19.9266 22.0005 20.3638 21.8736 20.7446 21.662L16.8664 14.9492Z'
            fill='#2684FC'
          />
          <path
            d='M20.7025 7.47379L17.1205 1.26866C16.8949 0.873788 16.5705 0.563532 16.1897 0.337891L12.3115 7.05071L16.8667 14.9481H24.609C24.609 14.511 24.4961 14.0738 24.2705 13.6789L20.7025 7.47379Z'
            fill='#FFBA00'
          />
        </g>
        <defs>
          <clipPath id='clip0_451_3224'>
            <rect width='24.6231' height='22' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: 'Google Sheets',
    logo: (
      <svg
        // width='18'
        // height='24'
        viewBox='0 0 18 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_451_3248)'>
          <path
            d='M11.4546 0L17.4546 6L14.4546 6.54545L11.4546 6L10.9092 3L11.4546 0Z'
            fill='#188038'
          />
          <path
            d='M11.4545 6V0H1.63636C0.732273 0 0 0.732273 0 1.63636V22.3636C0 23.2677 0.732273 24 1.63636 24H15.8182C16.7223 24 17.4545 23.2677 17.4545 22.3636V6H11.4545Z'
            fill='#34A853'
          />
          <path
            d='M3.27246 9.27344V17.1825H14.1816V9.27344H3.27246ZM8.04519 15.8189H4.6361V13.9098H8.04519V15.8189ZM8.04519 12.5462H4.6361V10.6371H8.04519V12.5462ZM12.8179 15.8189H9.40882V13.9098H12.8179V15.8189ZM12.8179 12.5462H9.40882V10.6371H12.8179V12.5462Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_451_3248'>
            <rect width='17.4545' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
  },

  {
    name: 'Google Slides',
    logo: (
      <svg
        width='48px'
        height='66px'
        viewBox='0 0 48 66'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-1'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-3'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-5'
          ></path>
          <linearGradient
            x1='50.0053945%'
            y1='8.58610612%'
            x2='50.0053945%'
            y2='100.013939%'
            id='linearGradient-7'
          >
            <stop stopColor='#BF360C' stopOpacity='0.2' offset='0%'></stop>
            <stop stopColor='#BF360C' stopOpacity='0.02' offset='100%'></stop>
          </linearGradient>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-8'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-10'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-12'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-14'
          ></path>
          <path
            d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
            id='path-16'
          ></path>
          <radialGradient
            cx='3.16804688%'
            cy='2.71744318%'
            fx='3.16804688%'
            fy='2.71744318%'
            r='161.248516%'
            gradientTransform='translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)'
            id='radialGradient-18'
          >
            <stop stopColor='#FFFFFF' stopOpacity='0.1' offset='0%'></stop>
            <stop stopColor='#FFFFFF' stopOpacity='0' offset='100%'></stop>
          </radialGradient>
        </defs>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Consumer-Apps-Slides-Large-VD-R8'
            transform='translate(-449.000000, -452.000000)'
          >
            <g id='Hero' transform='translate(0.000000, 63.000000)'>
              <g id='Personal' transform='translate(277.000000, 299.000000)'>
                <g
                  id='Slides-icon'
                  transform='translate(172.000000, 90.000000)'
                >
                  <g id='Group'>
                    <g id='Clipped'>
                      <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <path
                        d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z'
                        id='Path'
                        fill='#F4B400'
                        fillRule='nonzero'
                        mask='url(#mask-2)'
                      ></path>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-4' fill='white'>
                        <use xlinkHref='#path-3'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <path
                        d='M33.28125,29.5833333 L14.0520833,29.5833333 C12.8317708,29.5833333 11.8333333,30.5817708 11.8333333,31.8020833 L11.8333333,51.03125 C11.8333333,52.2515625 12.8317708,53.25 14.0520833,53.25 L33.28125,53.25 C34.5015625,53.25 35.5,52.2515625 35.5,51.03125 L35.5,31.8020833 C35.5,30.5817708 34.5015625,29.5833333 33.28125,29.5833333 Z M32.5416667,46.59375 L14.7916667,46.59375 L14.7916667,36.2395833 L32.5416667,36.2395833 L32.5416667,46.59375 Z'
                        id='Shape'
                        fill='#F1F1F1'
                        fillRule='nonzero'
                        mask='url(#mask-4)'
                      ></path>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-6' fill='white'>
                        <use xlinkHref='#path-5'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <polygon
                        id='Path'
                        fill='url(#linearGradient-7)'
                        fillRule='nonzero'
                        mask='url(#mask-6)'
                        points='30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75'
                      ></polygon>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-9' fill='white'>
                        <use xlinkHref='#path-8'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <g id='Group' mask='url(#mask-9)'>
                        <g transform='translate(26.625000, -2.958333)'>
                          <path
                            d='M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z'
                            id='Path'
                            fill='#FADA80'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-11' fill='white'>
                        <use xlinkHref='#path-10'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <polygon
                        id='Path'
                        fillOpacity='0.1'
                        fill='#FFFFFF'
                        fillRule='nonzero'
                        mask='url(#mask-11)'
                        points='29.5833333 0 29.5833333 0.369791667 46.9635417 17.75 47.3333333 17.75'
                      ></polygon>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-13' fill='white'>
                        <use xlinkHref='#path-12'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <path
                        d='M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z'
                        id='Path'
                        fillOpacity='0.2'
                        fill='#FFFFFF'
                        fillRule='nonzero'
                        mask='url(#mask-13)'
                      ></path>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-15' fill='white'>
                        <use xlinkHref='#path-14'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <path
                        d='M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z'
                        id='Path'
                        fillOpacity='0.2'
                        fill='#BF360C'
                        fillRule='nonzero'
                        mask='url(#mask-15)'
                      ></path>
                    </g>
                    <g id='Clipped'>
                      <mask id='mask-17' fill='white'>
                        <use xlinkHref='#path-16'></use>
                      </mask>
                      <g id='SVGID_1_'></g>
                      <path
                        d='M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642188 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z'
                        id='Path'
                        fillOpacity='0.1'
                        fill='#BF360C'
                        fillRule='nonzero'
                        mask='url(#mask-17)'
                      ></path>
                    </g>
                  </g>
                  <path
                    d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z'
                    id='Path'
                    fill='url(#radialGradient-18)'
                    fillRule='nonzero'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
  },
  {
    name: 'Microsoft OneDrive',
    logo: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 5.5 32 20.5'>
        <g id='STYLE_COLOR'>
          <path
            d='M12.20245,11.19292l.00031-.0011,6.71765,4.02379,4.00293-1.68451.00018.00068A6.4768,6.4768,0,0,1,25.5,13c.14764,0,.29358.0067.43878.01639a10.00075,10.00075,0,0,0-18.041-3.01381C7.932,10.00215,7.9657,10,8,10A7.96073,7.96073,0,0,1,12.20245,11.19292Z'
            fill='#0364b8'
          />
          <path
            d='M12.20276,11.19182l-.00031.0011A7.96073,7.96073,0,0,0,8,10c-.0343,0-.06805.00215-.10223.00258A7.99676,7.99676,0,0,0,1.43732,22.57277l5.924-2.49292,2.63342-1.10819,5.86353-2.46746,3.06213-1.28859Z'
            fill='#0078d4'
          />
          <path
            d='M25.93878,13.01639C25.79358,13.0067,25.64764,13,25.5,13a6.4768,6.4768,0,0,0-2.57648.53178l-.00018-.00068-4.00293,1.68451,1.16077.69528L23.88611,18.19l1.66009.99438,5.67633,3.40007a6.5002,6.5002,0,0,0-5.28375-9.56805Z'
            fill='#1490df'
          />
          <path
            d='M25.5462,19.18437,23.88611,18.19l-3.80493-2.2791-1.16077-.69528L15.85828,16.5042,9.99475,18.97166,7.36133,20.07985l-5.924,2.49292A7.98889,7.98889,0,0,0,8,26H25.5a6.49837,6.49837,0,0,0,5.72253-3.41556Z'
            fill='#28a8ea'
          />
        </g>
      </svg>
    ),
  },
  {
    name: 'Notion',
    logo: (
      <svg
        // width='800px'
        // height='800px'
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.25781 3.11684C3.67771 3.45796 3.83523 3.43193 4.62369 3.37933L12.0571 2.93299C12.2147 2.93299 12.0836 2.77571 12.0311 2.74957L10.7965 1.85711C10.56 1.67347 10.2448 1.46315 9.64083 1.51576L2.44308 2.04074C2.18059 2.06677 2.12815 2.19801 2.2327 2.30322L3.25781 3.11684ZM3.7041 4.84917V12.6704C3.7041 13.0907 3.91415 13.248 4.38693 13.222L12.5562 12.7493C13.0292 12.7233 13.0819 12.4341 13.0819 12.0927V4.32397C13.0819 3.98306 12.9508 3.79921 12.6612 3.82545L4.12422 4.32397C3.80918 4.35044 3.7041 4.50803 3.7041 4.84917ZM11.7688 5.26872C11.8212 5.50518 11.7688 5.74142 11.5319 5.76799L11.1383 5.84641V11.6205C10.7965 11.8042 10.4814 11.9092 10.2188 11.9092C9.79835 11.9092 9.69305 11.7779 9.37812 11.3844L6.80345 7.34249V11.2532L7.61816 11.437C7.61816 11.437 7.61816 11.9092 6.96086 11.9092L5.14879 12.0143C5.09615 11.9092 5.14879 11.647 5.33259 11.5944L5.80546 11.4634V6.29276L5.1489 6.24015C5.09625 6.00369 5.22739 5.66278 5.5954 5.63631L7.53935 5.50528L10.2188 9.5998V5.97765L9.53564 5.89924C9.4832 5.61018 9.69305 5.40028 9.95576 5.37425L11.7688 5.26872ZM1.83874 1.33212L9.32557 0.780787C10.245 0.701932 10.4815 0.754753 11.0594 1.17452L13.4492 2.85424C13.8436 3.14309 13.975 3.22173 13.975 3.53661V12.7493C13.975 13.3266 13.7647 13.6681 13.0293 13.7203L4.33492 14.2454C3.78291 14.2717 3.52019 14.193 3.23111 13.8253L1.47116 11.5419C1.1558 11.1216 1.02466 10.8071 1.02466 10.4392V2.25041C1.02466 1.77825 1.23504 1.38441 1.83874 1.33212Z'
          fill='#000000'
        />
      </svg>
    ),
  },
  { name: 'Other' },
];

export function FilesNew() {
  const [showAddFileDialog, setShowAddFileDialog] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState('');

  return (
    <PageLayout>
      <FileDialog
        open={showAddFileDialog}
        serviceName={selectedFileType}
        onOpenChange={() => {
          setShowAddFileDialog(false);
          setSelectedFileType('');
        }}
      />
      <Header>
        <h1>Add a file</h1>
      </Header>
      <div className={clsx(styles['grid'])}>
        {fileTypes.map((fileType) => (
          <Card
            asButton
            className={clsx(styles.card)}
            onClick={() => {
              setShowAddFileDialog(true);
              setSelectedFileType(fileType.name);
            }}
          >
            {fileType.logo}
            <h2>{fileType.name}</h2>
          </Card>
        ))}
      </div>
    </PageLayout>
  );
}
