import React from 'react';

import { Button, EmptyState } from '../../../../ui';

import { useRequest, useRouter } from '../../../../hooks';

import styles from '../../Billing.module.css';

export function StartSubscription() {
  const { data, request } = useRequest();

  const ROUTER = useRouter();

  if (data?.url) {
    window.location = data?.url;
  }

  return (
    <EmptyState className={styles['empty-state-base']}>
      <svg
        width='22'
        height='18'
        viewBox='0 0 22 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='mb-4'
      >
        <path
          d='M1 6H21M5 10H8M14.6 17H7.4C5.15979 17 4.03969 17 3.18404 16.564C2.43139 16.1805 1.81947 15.5686 1.43597 14.816C1 13.9603 1 12.8402 1 10.6V7.4C1 5.15979 1 4.03968 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H14.6C16.8402 1 17.9603 1 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C21 4.03968 21 5.15979 21 7.4V10.6C21 12.8402 21 13.9603 20.564 14.816C20.1805 15.5686 19.5686 16.1805 18.816 16.564C17.9603 17 16.8402 17 14.6 17Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>

      <p className='mb-8'>
        Looking to start a subscription? We're happy to have you!
      </p>
      <Button
        onClick={() =>
          request({ method: 'post', url: ROUTER.API.BILLING_CREATE })
        }
        label='Start subscription'
      />
    </EmptyState>
  );
}
