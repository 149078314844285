import React from 'react';
import clsx from 'clsx';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './DropdownMenu.module.css';

import { ButtonItem, Card } from '../../';

export const Dropdown = ({ items }) => {
  if (!items) {
    return;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <ButtonItem />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={clsx(styles['menu-content'])}
          align='end'
        >
          <Card className={clsx(styles.card)}>
            {items.map((item) => {
              return (
                <DropdownMenu.Item
                  className={clsx(styles['menu-item'])}
                  onClick={() => item.onClick()}
                  key={item.text}
                >
                  <span
                    className={clsx(
                      styles['menu-item-text'],
                      item.danger && styles.danger
                    )}
                  >
                    {item.icon} {item.text}
                  </span>
                </DropdownMenu.Item>
              );
            })}
          </Card>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
