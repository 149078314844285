import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Switch,
  CardRow,
} from '../../../../../ui';

import { useRequest, useRouter } from '../../../../../hooks';

import styles from './AddPeopleToProjectDialog.module.css';

export function AddPeopleToProjectDialog({ open, onOpenChange }) {
  const { data, request } = useRequest();

  const ROUTER = useRouter();

  const fetchOrganizationUsers = () => {
    request({
      url: ROUTER.API.ORGANIZATION_PROJECT_USERS_SHOW,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  const addUserToProject = (userId) => {
    request({
      method: 'post',
      url: ROUTER.API.PROJECT_USERS_CREATE,
      data: {
        project_unique_id: ROUTER.PARAMS.PROJECT_UNIQUE_ID,
        user_id: userId,
      },
      onSuccess: fetchOrganizationUsers,
      onError: () => {},
    });
  };

  const removeUserFromProject = (userId) => {
    request({
      method: 'delete',
      url: ROUTER.API.PROJECT_USERS_DESTROY,
      data: {
        project_unique_id: ROUTER.PARAMS.PROJECT_UNIQUE_ID,
        user_id: userId,
      },
      onSuccess: fetchOrganizationUsers,
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);
  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='md'>
      <DialogHeader>
        <h1>People on project</h1>
      </DialogHeader>
      <DialogBody className={clsx(styles.body)}>
        {data?.length &&
          data.map((user) => {
            return (
              <CardRow className={clsx(styles['card-row'])}>
                <div className={styles['avatar-wrapper']}>
                  <img
                    src={user.avatar_url}
                    className={styles.avatar}
                    alt={user.email}
                  />
                  <span>{user.email}</span>
                </div>
                {user.is_current_user ? (
                  <span>{'(You)'}</span>
                ) : (
                  <Switch
                    checked={user.is_project_user}
                    id={user.email}
                    onCheckedChange={
                      user.is_project_user
                        ? () => removeUserFromProject(user.id)
                        : () => addUserToProject(user.id)
                    }
                  />
                )}
              </CardRow>
            );
          })}
      </DialogBody>
      <DialogFooter>
        <Button variant='secondary' label='Close' onClick={onOpenChange} />
      </DialogFooter>
    </Dialog>
  );
}
