import React from 'react';
import clsx from 'clsx';

import { DotsIcon } from '../..';

import styles from './ButtonItem.module.css';

export const ButtonItem = React.forwardRef((props, forwardedRef) => {
  return (
    <button {...props} ref={forwardedRef} className={clsx(styles.base)}>
      {props.children?.icon || <DotsIcon />}
    </button>
  );
});
