import React from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '../../../../../ui';

import { useRequest } from '../../../../../hooks/useRequest';

export function DeleteChecklistModal({
  open,
  onOpenChange,
  onSuccess,
  checklistId,
}) {
  const { request } = useRequest();

  const deleteChecklist = () => {
    request({
      method: 'delete',
      url: `/api/v1/checklists/${checklistId}`,
      onSuccess: () => onSuccess(),
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Delete checklist</h1>
      </DialogHeader>
      <DialogBody>
        <p>
          Are you sure you want to delete this checklist? Deleting a checklist
          will delete all related tasks and files. This can't be undone.
        </p>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='secondary'
          label='Cancel'
          onClick={() => onOpenChange()}
        />
        <Button
          variant='danger'
          label='Yes, delete'
          onClick={deleteChecklist}
        />
      </DialogFooter>
    </Dialog>
  );
}
