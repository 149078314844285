import React from 'react';

export function SuperSweetLogo({ className }: { className?: string }) {
  return (
    <svg
      width='105'
      height='66'
      className={className}
      viewBox='0 0 105 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M83.0159 26.1083C81.3482 26.225 80.0902 25.3552 80.3606 23.3526L82.0432 11.7085C82.3405 9.6013 83.3361 8.6766 84.7997 8.57425C87.4886 8.38623 87.2832 10.829 87.0935 12.5182L87.0002 13.1404L87.0682 13.1356C87.9401 10.441 89.2806 8.0899 91.5271 7.93282C92.7864 7.84475 93.4467 8.48265 93.5324 9.70796C93.6847 11.8863 92.1397 12.781 90.5226 13.1335C88.8058 13.5272 87.4112 14.6166 86.9542 17.8636L86.1809 22.9456C85.8765 24.9506 84.8199 25.9822 83.0159 26.1083Z'
        fill='black'
      />
      <path
        d='M70.9917 27.1202C66.2266 27.4535 63.1074 24.4223 62.8146 20.2358C62.4647 15.2324 65.3341 9.79867 72.2775 9.31314C76.2258 9.03705 78.5517 10.995 78.7302 13.5477C78.992 17.2918 74.6444 19.6822 68.5256 19.7338C68.8457 21.8662 70.1299 23.1103 72.0359 22.9771C75.2013 22.7557 75.3339 20.2496 77.1378 20.1235C77.7505 20.0806 78.1875 20.4605 78.2446 21.2774C78.3755 23.1494 76.1312 26.7609 70.9917 27.1202ZM68.5094 17.5459C71.5632 17.1956 74.0446 15.996 73.897 13.8857C73.8327 12.9667 73.1115 12.4357 72.1585 12.5023C69.9461 12.6571 68.7275 14.7945 68.5094 17.5459Z'
        fill='black'
      />
      <path
        d='M44.4731 35.6443C42.6352 35.7729 41.4089 34.8667 41.6817 32.8981L44.3178 14.3467C44.5732 12.6187 45.4746 11.3243 47.5848 11.1768C49.3207 11.0554 50.3839 12.0755 50.104 13.9421L49.9664 14.9094L50.0345 14.9046C50.8811 12.8274 52.5763 10.6567 55.7757 10.433C58.805 10.2212 60.6711 12.4507 60.9036 16.2652C61.2068 21.5797 58.6788 27.9814 53.0287 28.3765C50.7823 28.5336 48.8903 27.4004 48.4997 25.2387L48.4316 25.2434L47.3651 32.9795C47.1413 34.6711 46.175 35.5253 44.4731 35.6443ZM51.3105 24.8369C53.5569 24.6798 54.7065 21.5554 54.8651 17.953C54.9091 16.1371 54.3445 14.9111 52.6087 15.0325C50.2942 15.1943 49.2357 19.1333 49.0557 22.4293C49.0924 23.9317 49.915 24.9345 51.3105 24.8369Z'
        fill='black'
      />
      <path
        d='M27.5671 30.1565C23.9932 30.4064 22.6163 27.8349 23.233 23.4479L24.336 15.7435C24.5819 13.8794 25.7651 12.7021 27.6371 12.5712C29.4751 12.4427 30.3681 13.4748 30.1223 15.3389L29.1323 22.2145C28.8572 24.1492 29.3299 25.0396 30.5212 24.9563C32.5293 24.8159 33.8556 22.2605 34.2857 19.1179L34.8534 15.0081C35.0969 13.1099 36.4186 11.9572 38.1885 11.8334C40.0265 11.7049 40.9171 12.7029 40.6396 14.6035L38.9982 26.3473C38.689 28.2843 37.562 29.2866 35.8261 29.408C34.0562 29.5317 32.9051 28.7229 33.1779 26.7543L33.4554 24.8537L33.3874 24.8585C32.5265 26.7314 31.2771 29.8971 27.5671 30.1565Z'
        fill='black'
      />
      <path
        d='M11.6177 31.272C6.20587 31.6504 2.27929 28.8125 2.08651 26.0555C1.97464 24.4558 3.02645 23.3562 4.62617 23.2443C5.9536 23.1515 6.67408 24.1614 7.46977 25.2686C8.54894 27.0059 9.63906 27.9216 11.8514 27.7669C13.8256 27.6289 15.0745 26.4128 14.9578 24.745C14.8602 23.3495 13.7994 22.3634 11.4428 21.4337C7.252 19.7088 4.54621 18.1878 4.28679 14.4778C3.97976 10.0871 7.31726 6.94647 13.3417 6.5252C18.243 6.18246 21.5988 8.68405 21.7535 10.8964C21.8821 12.7344 20.7891 13.7343 19.1213 13.8509C17.3514 13.9747 16.6949 12.4131 16.341 11.7538C15.7907 10.7319 14.8439 9.90887 13.0059 10.0374C11.0318 10.1754 9.84619 11.3186 9.95091 12.8162C10.0723 14.5521 11.4378 15.0039 13.9789 16.1259C17.3971 17.5628 20.4551 19.2302 20.7074 22.838C21.0335 27.5011 17.6422 30.8507 11.6177 31.272Z'
        fill='black'
      />
      <path
        d='M97.0414 59.5015C92.4464 59.8228 91.7367 56.5206 92.3827 52.0632L93.3869 45.3918L92.3318 45.4656C91.6851 45.5108 91.0779 45.1428 91.016 44.2579C90.9374 43.1347 91.5294 42.3066 92.7207 42.2233L94.2183 42.1186L94.5108 39.9433C94.7053 38.3222 95.541 37.0666 97.5151 36.9286C99.9317 36.7596 100.154 37.9754 99.925 39.5989L99.6301 41.7401L102.251 41.5569C102.966 41.5069 103.444 41.9865 103.496 42.7353C103.577 43.8925 102.985 44.7206 101.794 44.8039L99.1391 44.9896L97.938 53.2481C97.7364 54.7671 97.9178 55.4043 98.7687 55.3448C99.4835 55.2948 99.7153 54.6971 100.532 54.64C101.179 54.5948 101.523 55.118 101.566 55.7306C101.699 57.6367 99.9345 59.2992 97.0414 59.5015Z'
        fill='black'
      />
      <path
        d='M81.6616 60.5772C76.8965 60.9104 73.7773 57.8792 73.4845 53.6927C73.1347 48.6893 76.004 43.2556 82.9475 42.77C86.8957 42.494 89.2216 44.4519 89.4001 47.0047C89.6619 50.7487 85.3143 53.1391 79.1955 53.1907C79.5157 55.3231 80.7998 56.5673 82.7058 56.434C85.8712 56.2126 86.0038 53.7065 87.8078 53.5804C88.4204 53.5375 88.8574 53.9174 88.9145 54.7343C89.0454 56.6063 86.8012 60.2178 81.6616 60.5772ZM79.1794 51.0029C82.2331 50.6525 84.7145 49.4529 84.5669 47.3426C84.5027 46.4236 83.7815 45.8926 82.8284 45.9593C80.6161 46.114 79.3974 48.2514 79.1794 51.0029Z'
        fill='black'
      />
      <path
        d='M64.144 61.8014C59.3789 62.1346 56.2597 59.1034 55.967 54.9169C55.6171 49.9135 58.4864 44.4798 65.4299 43.9943C69.3781 43.7182 71.704 45.6761 71.8825 48.2289C72.1444 51.9729 67.7967 54.3633 61.678 54.415C61.9981 56.5474 63.2822 57.7915 65.1883 57.6582C68.3537 57.4369 68.4862 54.9308 70.2902 54.8046C70.9028 54.7618 71.3398 55.1417 71.397 55.9585C71.5279 57.8306 69.2836 61.442 64.144 61.8014ZM61.6618 52.2271C64.7156 51.8767 67.1969 50.6771 67.0493 48.5669C66.9851 47.6479 66.2639 47.1169 65.3109 47.1835C63.0985 47.3382 61.8798 49.4756 61.6618 52.2271Z'
        fill='black'
      />
      <path
        d='M34.0262 63.8739C29.9758 64.1572 28.3195 61.5025 29.0193 56.8362L29.9816 50.544C30.3058 48.3323 30.938 46.6121 33.2524 46.4503C35.2266 46.3122 36.0199 47.3855 35.6886 49.495L34.7872 55.6804C34.4337 57.9625 34.4339 59.4332 36.2719 59.3047C38.1439 59.1738 39.3474 55.8404 39.8098 52.1825L40.0612 49.9075C40.3956 47.3531 41.0421 45.8372 43.3225 45.6777C45.3647 45.5349 46.0631 46.7174 45.7682 48.8586L45.0148 55.2046C44.7761 57.1709 44.7028 59.0572 46.3365 58.943C48.1405 58.8168 50.0222 55.4017 49.899 51.6822C49.8702 49.8031 49.3682 48.9831 49.2896 47.8599C49.1706 46.158 50.1639 45.1993 51.7296 45.0898C53.6016 44.9589 54.1828 46.4232 54.3042 48.1591C54.5993 52.3796 51.4189 62.6577 44.4754 63.1433C41.6164 63.3432 40.2792 61.8291 39.8379 59.4316C38.6383 61.8413 36.8172 63.6788 34.0262 63.8739Z'
        fill='black'
      />
      <path
        d='M17.4004 65.0703C11.9886 65.4487 8.06201 62.6108 7.86922 59.8539C7.75736 58.2541 8.80916 57.1545 10.4089 57.0426C11.7363 56.9498 12.4568 57.9597 13.2525 59.067C14.3317 60.8043 15.4218 61.7199 17.6342 61.5652C19.6083 61.4272 20.8572 60.2112 20.7405 58.5434C20.643 57.1479 19.5821 56.1618 17.2255 55.232C13.0347 53.5071 10.3289 51.9862 10.0695 48.2762C9.76247 43.8854 13.1 40.7448 19.1245 40.3235C24.0257 39.9808 27.3815 42.4824 27.5362 44.6948C27.6648 46.5327 26.5718 47.5327 24.904 47.6493C23.1341 47.773 22.4776 46.2114 22.1237 45.5521C21.5734 44.5303 20.6266 43.7072 18.7886 43.8357C16.8145 43.9738 15.6289 45.117 15.7336 46.6146C15.855 48.3505 17.2205 48.8022 19.7616 49.9242C23.1798 51.3612 26.2378 53.0285 26.4901 56.6364C26.8162 61.2994 23.4249 64.649 17.4004 65.0703Z'
        fill='black'
      />
    </svg>
  );
}
