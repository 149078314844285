import React, { useContext } from 'react';

import { Header, PageLayout } from '../../ui';
import { AccountContext } from '../../contexts/account';

import { NonAdmin } from './components/NonAdmin';
import { StartSubscription } from './components/StartSubscription';
import { ManageSubscription } from './components/ManageSubscription';

export function Billing() {
  const { permissions } = useContext(AccountContext);

  let body;

  if (!permissions.is_organization_admin) {
    body = <NonAdmin />;
  }

  if (permissions.can_start_subscription) {
    body = <StartSubscription />;
  }

  if (permissions.can_manage_subscription) {
    body = <ManageSubscription />;
  }

  return (
    <PageLayout>
      <Header>
        <h1>Billing</h1>
      </Header>
      {body}
    </PageLayout>
  );
}
