import React from 'react';
import clsx from 'clsx';

import { useRequest } from '../../../../hooks';

import styles from './MenuBar.module.css';

import { MenuButton } from './components/MenuButton';

export function MenuBar({
  editor,
  attachments,
  setAttachments,
  setIsUploading,
  hideFeatures = [],
}) {
  const { request } = useRequest();

  const uploadFile = (data) => {
    setIsUploading(true);

    request({
      method: 'post',
      url: `/api/v1/uploads`,
      data,
      onSuccess: (res) => {
        setIsUploading(false);
        setAttachments([...attachments, res]);
      },
    });
  };

  return (
    <div className={clsx(styles.base, 'menubar')}>
      <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.20001 11.52V5.54182C7.20001 5.29767 7.20001 5.17558 7.24338 5.0806C7.29136 4.97557 7.37558 4.89135 7.48061 4.84338C7.57559 4.8 7.69768 4.8 7.94183 4.8H12C13.8557 4.8 15.36 6.30432 15.36 8.16C15.36 10.0179 13.81 11.52 11.9662 11.52M7.20001 11.52V17.472C7.20001 17.7408 7.20001 17.8752 7.25233 17.9779C7.29835 18.0683 7.37178 18.1417 7.46209 18.1877C7.56477 18.24 7.69918 18.24 7.96801 18.24H13.44C15.2957 18.24 16.8 16.7357 16.8 14.88C16.8 13.0243 15.2957 11.52 13.44 11.52H11.9662M7.20001 11.52H11.9662'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor?.isActive('bold')}
      />
      <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.44 4.8L10.56 18.24M13.44 4.8H16.8M13.44 4.8H10.08M10.56 18.24H13.92M10.56 18.24H7.20001'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor?.isActive('italic')}
      />
      <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 11.52C14.1803 11.52 16.8 11.9377 16.8 15.2332C16.8 20.1006 8.47739 20.5192 7.3398 16.32M12 11.52H20.64M12 11.52H3.35999M7.19999 7.80678C7.19999 2.93944 15.5226 2.52075 16.6602 6.72'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        onClick={() => editor.chain().focus().toggleStrike().run()}
        isActive={editor?.isActive('strike')}
      />
      {/* <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.0505 11.3635C13.8684 11.0887 13.6563 10.8278 13.4141 10.5857C12.2574 9.42898 10.675 8.95749 9.1712 9.1712C8.13646 9.31826 7.13896 9.78975 6.34303 10.5857L4.92882 11.9999C2.9762 13.9525 2.9762 17.1183 4.92882 19.071C6.88144 21.0236 10.0473 21.0236 11.9999 19.071L12.707 18.3638M9.94929 12.6363C10.1314 12.9111 10.3435 13.1719 10.5857 13.4141C11.7424 14.5708 13.3248 15.0423 14.8286 14.8286C15.8633 14.6815 16.8608 14.21 17.6567 13.4141L19.071 11.9999C21.0236 10.0473 21.0236 6.88144 19.071 4.92882C17.1183 2.9762 13.9525 2.9762 11.9999 4.92882L11.2928 5.63593'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      /> */}
      <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21 6H9M21 12H9M21 18H9M4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6C5 6.55228 4.55228 7 4 7ZM4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13ZM4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18C5 18.5523 4.55228 19 4 19Z'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor?.isActive('bulletList')}
      />
      <MenuButton
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5 9.99978V4.75488C4.3236 4.92051 3.80674 5.38628 3.5 5.99976M7 18.9998H3.60797V18.4998C4.49018 17.8818 5.39421 17.2985 6.11723 16.4886C6.60019 15.9476 6.65362 15.138 6.13314 14.5942C5.71038 14.1526 4.9793 14.0078 4.41574 14.227C4.03522 14.375 3.81857 14.6715 3.60797 14.9998M21 5.99978H11M21 11.9998H11M21 17.9998H11'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor?.isActive('orderedList')}
      />

      {!hideFeatures.includes('attachments') && (
        <>
          <MenuButton
            icon={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 9V16C18 19.3137 15.3137 22 12 22C8.68629 22 6 19.3137 6 16V6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6V16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16V7'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            onClick={() => {
              document.getElementById('file')?.click();
            }}
          />
          <input
            id='file'
            type='file'
            name='name'
            style={{ display: 'none' }}
            onChange={async (event) => {
              const file = event.currentTarget.files[0];
              const formData = new FormData();
              formData.append('file', file);
              uploadFile(formData);
            }}
          />
        </>
      )}
    </div>
  );
}
