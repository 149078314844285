import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogBody,
  DialogFooter,
  Input,
  Label,
  Button,
  DialogHeader,
} from '../../../../../ui';
import { EmojiPicker } from '../../../../../features';
import { useRequest, useRouter } from '../../../../../hooks';

export function NewCategoryDialog({ open, onOpenChange, handleRefetch }) {
  const [emoji, setEmoji] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const name = watch('name');

  const { request, isLoading } = useRequest();
  const ROUTER = useRouter();

  const onSubmit = (data) => {
    request({
      method: 'post',
      url: ROUTER.API.POST_CATEGORIES_CREATE,
      data: {
        ...data,
        emoji,
      },
      onSuccess: () => {
        handleRefetch();
        onOpenChange(false);
        reset();
      },
      onError: () => {},
    });
  };

  return (
    <Dialog open={open} onOpenChange={() => onOpenChange(false)}>
      <DialogHeader>
        <h1>Create new category</h1>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          <Label label='Choose an emoji'></Label>
          <EmojiPicker getEmoji={setEmoji} />
          <Label label='Name'>
            <Input register={register} name='name' />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            label='Cancel'
            variant='secondary'
            onClick={() => onOpenChange(false)}
          />
          <Button
            label={isLoading ? 'Creating...' : 'Create'}
            type='submit'
            disabled={isLoading || !emoji || !name}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
