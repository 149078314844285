import React from 'react';
import clsx from 'clsx';

import styles from './Card.module.css';

export function Card({
  children,
  className,
  asButton,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  asButton?: boolean;
  onClick?: () => void;
}) {
  if (asButton && onClick) {
    return (
      <button
        className={clsx(styles.base, styles.button, className)}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return <div className={clsx(styles.base, className)}>{children}</div>;
}
