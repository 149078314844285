import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.css';

export function Button({
  label,
  onClick,
  variant = 'primary',
  disabled,
  icon,
  className,
}: {
  label: string;
  variant?: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
}) {
  return (
    <button
      className={clsx(styles.base, styles[variant], className)}
      onClick={onClick}
      disabled={disabled}
    >
      {icon} {label}
    </button>
  );
}
