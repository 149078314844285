import React, { useContext, useEffect } from 'react';

import { AccountContext } from '../contexts/account';

// Router Controller
export function Router({ children }) {
  const account = useContext(AccountContext);

  useEffect(() => {
    const needsToCompleteOnboarding =
      account &&
      !account?.default_organization &&
      window.location.pathname !== '/onboarding';

    // User needs to complete onboarding, so redirect them through that flow
    if (needsToCompleteOnboarding) {
      window.location = `/onboarding`;
    }

    const redirectPaths = ['/', '/onboarding'];
    const hasDefaultOrganization = account?.default_organization;
    const hasCompletedOnboarding =
      redirectPaths.includes(location.pathname) && hasDefaultOrganization;

    if (hasCompletedOnboarding) {
      // User has completed onboarding, so we should redirect them to their
      // default organization's projects page
      window.location = `/${account?.default_organization?.slug}/projects`;
    }
  }, [account]);

  return <>{children}</>;
}
