import React, { useState, useContext } from 'react';

import { useRequest } from '../../../../hooks/useRequest';
import { AccountContext } from '../../../../contexts/account';

import clsx from 'clsx';

import { Button } from '../../../../ui';
import { Editor } from '../../../Editor/Editor';

import styles from '../../Comments.module.css';
import { AccountContext } from '../../../../contexts/account';

export function NewComment({
  handleRefetch = () => {},
  createEndpoint,
}: {
  handleRefetch: () => void;
  createEndpoint: string;
}) {
  const { request } = useRequest();
  const [content, setContent] = useState('');
  const [resetContent, setResetContent] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const account = useContext(AccountContext);

  return (
    <div className={clsx(styles['comment-row'])}>
      <div className={clsx(styles.wrapper)}>
        <div className={clsx(styles['avatar-wrapper'])}>
          <div
            className={clsx(styles.avatar)}
            style={{ backgroundImage: `url(${account?.avatar_url})` }}
          />
        </div>
        {!resetContent && (
          <Editor
            initialAttachments={[]}
            setContent={setContent}
            getAttachments={setAttachments}
          />
        )}
      </div>
      <div className={clsx(styles['actions-wrapper'])}>
        <Button
          label='Add comment'
          onClick={() => {
            request({
              method: 'post',
              url: createEndpoint,
              data: {
                body: content,
                attachments,
              },
              onSuccess: () => {
                handleRefetch();
                setResetContent(true);
                setTimeout(() => {
                  setResetContent(false);
                }, 100);
              },
              onError: () => {},
            });
          }}
        />
      </div>
    </div>
  );
}
