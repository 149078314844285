import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  Card,
  CardRow,
  EmptyState,
  PageLayout,
  Header,
  Button,
} from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';
import { toMonthAndDay } from '../../../utils/dates/toMonthAndDay';

import styles from './PostCategoriesIndex.module.css';

export function PostCategoriesIndex() {
  const { request, data, isLoading } = useRequest();

  const ROUTER = useRouter();

  useEffect(() => {
    request({
      url: ROUTER.API.CATEGORY_POSTS_INDEX,
    });
  }, []);

  const hasNoPosts = !isLoading && data?.posts?.length === 0;

  return (
    <PageLayout>
      <Header>
        <h1>
          <span className={clsx(styles.emoji)}>{data?.category?.emoji}</span>{' '}
          {data?.category?.name}
        </h1>
        {data?.posts?.length ? (
          <Button
            label='New Post'
            onClick={() => ROUTER.NAVIGATE(ROUTER.PATH.POSTS_NEW)}
          />
        ) : null}
      </Header>
      {hasNoPosts ? (
        <EmptyState>
          <h2>{data?.category?.name} has no posts yet</h2>
          <Button
            variant='secondary'
            label='Create a post'
            onClick={() => ROUTER.NAVIGATE(ROUTER.PATH.POSTS_NEW)}
          />
        </EmptyState>
      ) : null}

      {data?.posts?.length ? (
        <Card>
          {data?.posts?.map((post) => {
            return (
              <CardRow
                key={post.created_at}
                className={clsx(styles['card-row'])}
              >
                <div className={clsx(styles.details)}>
                  <div
                    className={clsx(styles.avatar)}
                    style={{ backgroundImage: `url(${post.user.avatar_url})` }}
                  />

                  <div className={clsx(styles['title-name'])}>
                    <ROUTER.LINK
                      to={`${ROUTER.PATH.POSTS_SHOW}/${post.unique_id}`}
                    >
                      <h3>{post.title}</h3>
                    </ROUTER.LINK>
                    <span>{post.user.name}</span>
                  </div>
                </div>
                <span className={clsx(styles.date)}>
                  {toMonthAndDay(post.created_at)}
                </span>
              </CardRow>
            );
          })}
        </Card>
      ) : null}
    </PageLayout>
  );
}
