import React from 'react';

export function DropboxLogo() {
  return (
    <svg
      width='26'
      height='22'
      viewBox='0 0 26 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.43695 0.0332031L0 4.16485L6.43695 8.23573L12.8739 4.16485L6.43695 0.0332031ZM19.3108 0.0332031L12.8739 4.16485L19.3108 8.23573L25.7478 4.16485L19.3108 0.0332031ZM0 12.3674L6.43695 16.499L12.8739 12.3674L6.43695 8.23573L0 12.3674ZM19.3108 8.23573L12.8739 12.3674L19.3108 16.499L25.7478 12.3674L19.3108 8.23573ZM6.43695 17.8357L12.8739 21.9674L19.3108 17.8357L12.8739 13.7648L6.43695 17.8357Z'
        fill='#0062FF'
      />
    </svg>
  );
}
