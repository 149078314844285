import React from 'react';
import clsx from 'clsx';

import styles from './Label.module.css';

export function Label({ label, name, className, children }) {
  return (
    <label className={clsx(styles.base, className)} htmlFor={name}>
      <span className={clsx(styles.span)}>{label}</span>
      {children}
    </label>
  );
}
